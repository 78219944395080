import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { formatDateEventJA, formatDateNoYearEventJA } from '../../helper/helper';
import TicketNumber from './TicketNumber';

const ExchangeTicketTop = () => {
    const { playerEvent } = useSelector((state) => state.event);
    let startAt = playerEvent.startAt * 1000;
    let endAt = playerEvent.endAt * 1000;
    const startDate = moment(startAt).format(formatDateEventJA);
    const endDate = moment(endAt).format(formatDateNoYearEventJA);
    return (
      <>
        <div className="bingo-select-top">
          <div className="event-image">
            <img src={ playerEvent?.banners?.images?.secondBanner } />
          </div>
          <div className="box-title"><h1>開催期間:{startDate}〜{endDate}</h1></div>
          <TicketNumber />
        </div>
      </>
    )
}
export default ExchangeTicketTop;