import {listPointHistory} from 'common/src/api/playerPointHistory'
import {getLocationFromAddress} from 'common/src/util/helper'
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getBingoHistory, setNeedReloadHistory } from '../../../../redux/bingoHistory/action'
import {serverApi} from '../../../../services/serverApi'
import BingoLogItem from './BingoLogItem';
import BingoLogClearItem from './BingoLogClearItem';
import BingoLogLineItem from './BingoLogLineItem';
import NextButton from '../../../../components/buttons/NextButton';
import Loading from '../../../../components/loading/Loading';
import { HEADER_TABBAR_COPYRIGHT_HOME } from '../../../../helper/helper';

const BingoLog = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const  size = 5
  const  page = 1
  const playerId = window.localStorage.getItem('user_id');
  const { isCheckUser } = useSelector((state) => state.user)
  const { playerEvent, isGettingEventPlayer } = useSelector((state) => state.event);
  const { bingoHistory, isBingoHistories, needReloadHistory, showBtn } = useSelector((state) => state.history)
  
  useEffect(() => {
    if (bingoHistory.length <= 0 || needReloadHistory) {
      if (isCheckUser && isGettingEventPlayer) {
        checkCallList()
        dispatch(setNeedReloadHistory(false))
      }
    }
  }, [])

  const checkCallList = async() => {
    const params = {
      playerId: playerId, 
      playerEventId: playerEvent.id, 
      size: size, 
      page: page
    }
    await dispatch(getBingoHistory(params))
  }

  return (
      <div
      className={((bingoHistory && bingoHistory.length === 2)) ? 'bingo-log-list class-list' : 'bingo-log-list'}
      style={{ minHeight: window.innerHeight - HEADER_TABBAR_COPYRIGHT_HOME + "px" }}
  >
    {
      !isBingoHistories ?
      <Loading /> : 
      <div>
        {bingoHistory && bingoHistory.slice(0,5).map((item, idx) =>
              item.type === 0 ? <BingoLogClearItem data={item} key={idx} /> :
                (item.type === 1 ? <BingoLogLineItem data={item} key={idx} /> :
                  <BingoLogItem data={item} key={idx} />))}
          <div className="box-button-next">
            { 
              showBtn &&
              <NextButton name="ビンゴログ一覧" link="/bingo-log" />
            }
          </div>
      </div>
    }
    </div>
  )
    
}

export default BingoLog;
