import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

const StyleLineSkip = styled.div`
  //position: absolute;
  //bottom: 0;
  //height: 180px;
  width: 100%;

  .line {
    width: 96%;
    margin:auto;
    display:block;
    min-height:5px;
  }

  .step-skip {
    width: 94%;
    margin: 8px auto 0 auto;
    position: relative;
    text-align: center;

    .oblong {
      width: 10px;
      margin: 0 6px;
    }

    .skip-next {
      position: absolute;
      top: 0;
      right: 0;
      font-size:12px;
    }

    .skip-prev {
      position: absolute;
      top: 0;
      left: 0;
      font-size:12px;
    }

    .btn {
      background-color: #ffffff;
      border: 2px solid #10cad6;
      border-radius: 20px;
      padding: 2px;
      width: 34%;
      height: 28px;
      font-size: 13px;
      color: #10cad6;
      outline: none;
      box-shadow: none;
      margin: 20px auto;
    }
  }
`
function getListOblong (activeStep) {
  const list = [];

  for (let i = 1; i <= 7; i++) {
    if (activeStep === i) {
      list.push('images/tutorial_oblong_dark.png')
    } else {
      list.push('images/tutorial_oblong.png')
    }
  }

  return list
}

const LineSkip = (props) => {
  const [listOblong, setListOblong] = useState(getListOblong(props.activeStep));
  let showPrev = true;
  if (props.showPrev !== undefined) {
    showPrev = props.showPrev
  }

  const eventClickImgOblong = (e, index) => {
    e.stopPropagation();
    props.actionToStep(index)
  }

  useEffect(() => {
    setListOblong(getListOblong(props.activeStep))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeStep])
  const prev = "<<戻る";
  const next = "進む>>";
  return(
    <StyleLineSkip>
        <img className="line" src="icon/rainbow_LINE.svg" alt="rainbow_LINE"/>
        <div className="step-skip">
          <div>
            {
              listOblong.map((oblong, index) => {
                return <img onClick={(e) => eventClickImgOblong(e, index + 1)} key={index} className="oblong" src={oblong} alt="rainbow_LINE"/>
              })
            }
          </div>
          {
            showPrev && <div className="color-blue skip-prev" onClick={props.actionPrev}>{prev}</div>
          }
          <div className="color-blue skip-next" onClick={props.actionNext}>{next}</div>
  
          <button
            className="btn"
            onClick={props.actionSkip}
          >
            SKIPする
          </button>
        </div>
      </StyleLineSkip>
  )
  
}
export default LineSkip
