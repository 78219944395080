import { takeLatest, put } from '@redux-saga/core/effects';
import { serverApi } from '../../services/serverApi';
import { actionTypes, getListSpotsSuccess, getListSpotsFail, getSpotDetailSuccess, getSpotDetailFail, checkinSpotSuccess, checkinSpotFail } from './action';
import { checkinSpot, getSpotDetail, getSpots } from 'common/src/api/spot';

function* getListSpotsSaga(action) {
  try {
    const { playsheetID, playerId, playerBingoId } = action.payload;
    const res = yield serverApi(getSpots(playsheetID, playerId, playerBingoId));
    if (res.status === 200) {
      const data = res.data.data;
      yield put(getListSpotsSuccess(data));
    } else {
      yield put(getListSpotsFail());
    }
  } catch (error) {
    yield put(getListSpotsFail());
  }
}
function* getSpotDetailSaga(action) {
  try {
    const res = yield serverApi(getSpotDetail(action.payload));
    if (res?.status === 200) {
      const { data } = res?.data;
      yield put(getSpotDetailSuccess(data));
    } else {
      yield put(getSpotDetailFail());
    }
  } catch (error) {
    yield put(getSpotDetailFail());
  }
}
function* checkinSpotSaga(action) {
  try {
    const { playerId, bingoId, spotId, latitude, longitude } = action.payload;
    const params = {
      bingoId: bingoId,
      spotId: spotId,
      latitude: latitude,
      longitude: longitude,
    }
    const res = yield serverApi(checkinSpot(playerId, params));
    console.log('res',res);
    if (res?.status === 200) {
      const { data } = res?.data;
      yield put(checkinSpotSuccess(data));
    } else {
      yield put(checkinSpotFail());
    }
  } catch (error) {
    yield put(checkinSpotFail());
  }
}


export function* watchGetListSpots() {
  yield takeLatest(actionTypes.GET_LIST_SPOTS, getListSpotsSaga);
}
export function* watchGetSpotDetail() {
  yield takeLatest(actionTypes.GET_SPOT_DETAIL, getSpotDetailSaga);
}
export function* watchCheckinSpot() {
  yield takeLatest(actionTypes.CHECKIN_SPOT, checkinSpotSaga);
}
