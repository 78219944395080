import React from 'react';
import './styles.scss';
import IconLocation from '../../../../static/svg/IconLocation'
import { Link } from 'react-router-dom'
import { formatDateBingoLog, glayColor, rgbBlue, rgbBlueColor, rgbGreenColor, rgbOrange, rgbOrangeColor, rgbRed, rgbRedColor } from '../../../../helper/helper';
import moment from 'moment';
import _ from 'lodash';

const BingoLogItem = (props) => {
  const date = moment((props.data.date)).format(formatDateBingoLog)
  const address = props.data.addresses.streetNumber +" " +props.data.addresses.localGovernments+" "+props.data.addresses.prefecture
  const design = `rgb(${props.data?.design.colors.red},${props.data?.design.colors.green},${props.data?.design.colors.blue})`;
  const isOrange = _.isEqual(design, rgbOrange);
  const isBlue = _.isEqual(design, rgbBlue);
  const isRed = _.isEqual(design, rgbRed);

  // let color = isOrange ? rgbOrangeColor : isRed ? rgbRedColor : isBlue ? rgbBlueColor : rgbGreenColor;
  let color = glayColor;

  return (
    <Link to={`/bingo/${props.data.playerBingoId}/spot/${props.data.id}/color=${design}`}>
      <div className="bingo-log-item">
        <div className="item-image">
            <img src={props.data.image_url}  onerror=""/>
          </div>
          <div className="item-text">
            <div className="title">
              <p>{props.data?.spotName + "で"}<span style={{ color: color }}>{ props.data?.genreName }パネル</span> を 獲得しました</p>
            </div>
            <div className="address"><IconLocation fill="#96A4BC" /><span className="address-text">{address}</span></div>
            <div className="date">{date}</div>
          </div>
      </div>
    </Link>
  )
}

export default BingoLogItem;
