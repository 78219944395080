import React, { useEffect, useState } from 'react';
import IconEdit from '../../static/svg/IconEdit';
import EditUserNameModal from '../../components/modals/EditUserNameModal';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../redux/user/action';
import { serverApi } from '../../services/serverApi';
import { detailPlayer}  from 'common/src/api/player'
import { disableScroll } from '../../services/helper';

const CardName = () => {
  const [display, setDisplay] = useState(0);
  const userInfo = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();
  const needUpdate  = useSelector((state) => state.user.userInfo.need_update)
  const userId = window.localStorage.getItem('user_id')
  const type = window.localStorage.getItem('typeLogin')
  const twitterId = window.localStorage.getItem('twitterId')

  useEffect(() => {
    const getInfoUser = async () => {
      if (!userId) {
        return;
      }
      const result = await serverApi(detailPlayer(userId))

      if (result?.data?.statusCode === 200) {
        const data = result?.data?.data
        // set user info to redux
        dispatch(getUserInfo(data))
      }
    }
    needUpdate && getInfoUser();
  }, [needUpdate]);

  useEffect(() => {
    disableScroll(display);
  }, [display]);

  return (
    <>
      <div className="card-name">
        <div className="left">
          <div className="user-name">{ userInfo?.nickName }</div>
          {type === 'twitter' && <div className="user-id">ID:{ twitterId }</div>}
        </div>
        <div className="right" onClick={() => { setDisplay(1) }}>
          <IconEdit />
        </div>
      </div>
      {display === 1 && <EditUserNameModal onClose={() => setDisplay(0)} name={ userInfo?.nickName } userId={userId} />}
    </>
  )
}
export default CardName;