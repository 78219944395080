import React from 'react';
import './styles.scss';
import BingoReward from '../../../../static/svg/BingoReward';
import IconPanelGet from '../../../../static/svg/IconPanelGet';
import IconBingoLineget from '../../../../static/svg/IconBingoLineget';
import IconDifficulty from '../../../../static/svg/IconDifficulty';
import { starColor, goldColor, mainColor } from '../../../../helper/helper';

const BingoRewardBox = (props) => {
  const sheetDetail = props.sheetDetail;
  const difficult = sheetDetail?.difficult;
  let checkedPanels = sheetDetail?.panels?.filter((x) => x.isCheckin === true).length + 1;
  let completeLines = sheetDetail?.clearLines?.filter((x) => x.completeAt !== 0).length;

  return (
    <>
      <div className="bingo-reward-box">
        <div className="reward-image">
          <BingoReward />
        </div>
        <div className="reward-content">
          <div className="reward-content-top">
            <div className="flex-content">
              <div className="icon-image"><IconPanelGet width="17.39" height="20" fill={ mainColor } /></div>
              <span className="text">パネル獲得</span>
            </div>

            <div className="flex-content">
              <span className="point">{ checkedPanels }</span>
              <span className="total">/19</span>
            </div>

          </div>
          <div className="reward-content-middle">
            <div className="flex-content">
              <div className="icon-image"><IconBingoLineget width="17.39" height="20" fill={goldColor} /></div>
              <span className="text">ビンゴ達成</span>
            </div>
            <div className="flex-content">
              <span className="point">{ completeLines }</span>
              <span className="total">/15</span></div>

          </div>
          <div className="reward-content-bottom">
            <div className="box-difficult">
              <span>難易度</span>
            </div>
            <div className="difficulty">
              <IconDifficulty fill={ difficult >= 1 ? starColor : null } />
              <IconDifficulty fill={ difficult >= 2 ? starColor : null } />
              <IconDifficulty fill={ difficult == 3 ? starColor : null } />
            </div>
          </div>
        </div>
      </div></>
  )
}
export default BingoRewardBox;