import React, { useState } from 'react';
import IconLocation from '../../static/svg/IconLocation';
import IconBingo from '../../static/svg/IconBingo';
import { mainColor } from '../../helper/helper';
import { Link } from 'react-router-dom';
import { calculateDistance } from 'common/src/util/geo';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_IMAGE_IMG_PATH } from '../../static/png';
import GenreIcon from '../../components/genre/GenreIcon';
const BingoSpotItem = (props) => {
  const dispatch = useDispatch();
  const spot = props.spot;
  const isDone = props.isDone ? props.isDone : false;
  // browser position
  const [currentPosition, setCurrentPosition] = useState({ lat: 0, lng: 0 })
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        setCurrentPosition(pos)
      }
    )
  }
  const spotPossition = { lat: spot.location.latitude, lng: spot.location.longitude }
  const distance = calculateDistance(currentPosition, spotPossition);
  const image = spot.image ? spot.image : DEFAULT_IMAGE_IMG_PATH;
  const genre = spot.genre;
  const color = `rgb(${spot.design.red},${spot.design.green},${spot.design.blue})`;
  const isCheckin = spot.isCheckin;

  return (
    <>
      <Link to={`/bingo/${ props.playerBingoId }/spot/${ spot.id }/color=${color}`}>
        <div className="bingo-spot-item" key={spot.id}>
          <div className="div-image">
            <img src={image} />
          </div>
          <div className="div-content">
            <div className="title">{spot.name}</div>
            <div className="text-middle">
              <div className="icon"><GenreIcon spot={spot} sizeCategory={'size02'} /></div>
              <span className="txt-icon" style={{ color: color }}>{genre}</span>
            </div>
            <div className="address">
              <IconLocation fill="#96A4BC" /><span className="address-text">現在地から{distance}m</span>
            </div>
          </div>
          {isCheckin && <div className="done-button">
             <IconBingo width="14" height="auto" fill={mainColor} />
             <span>完了</span>
          </div> }
        </div>
      </Link>
    </>
  )
}
export default BingoSpotItem;