import { listShop } from 'common/src/api'
import { calculateRLng, calculateH, getBasePoint, constructCenters, NUMBER_HEX_PER_SIDE, DISTANCE_REFRESH_SHOP } from 'common/src/googleMap/map'
import { transformShop, getShopDisplayInHex } from 'common/src/googleMap/shop'
import { calculateDistance } from 'common/src/util/geo'
import GoogleMapReact from 'google-map-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getShopList } from '../../../../redux/shop/action'
import { updateUserPosition } from '../../../../redux/user/action'
import { serverApi } from '../../../../services/serverApi'
import CurrentLocationButton from './CurrentLocationButton'
import InfoMarkerModal from './InfoMarkerModal/InfoMarkerModal'
import CurrentLocation from './markers/current/CurrentLocation'
import MarkerIcon from './InfoMarkerModal/MarkerIcon';
import Loading from '../../../../components/loading/Loading'
import { getListSpots } from '../../../../redux/spot/action'

const GoogleMap = (props) => {
  const { spots } = useSelector((state) => state.spot);
  // window height
  const [height, setHeight] = useState(0)

  // shops markers
  const [markers1, setMarkers] = useState([])
  //const testCurrentPossition = "35.424318, 139.610612"
  //const markers = [{ 'latitude': 35.422403, 'longitude': 139.611213 }, { 'latitude': 35.424361, 'longitude': 139.609089 },]

  // modal related
  const [modalVisible, setModalVisible] = useState(false)
  const [modalData, setModalData] = useState({})
  const [modalKey, setModalKey] = useState(null)

  // player position
  const [currentPosition, setCurrentPosition] = useState({ lat: 0, lng: 0 })
  // previous position to check whether get shop or not
  const [prevPosition, setPrevPosition] = useState({ lat: 0, lng: 0 })
  // map position
  const [mapPosition, setMapPosition] = useState({ lat: 0, lng: 0 })

  // map
  const [mapRef, setMapRef] = useState(null)
  const [mapsRef, setMapsRef] = useState(null)

  //redux
  const dispatch = useDispatch()
  const checkedShops = useSelector(state => state.bingo.shop_ids)
  //const dataShops = useSelector(state => state.shop.items)
  const getShops = async (position = null) => {
    findMyLocation();
    const curPos = !position ? currentPosition : position
    const curHexCenters = getPolygonsPoint(curPos)

    const result = await serverApi(listShop(curPos))

    if (result.error) {
      console.log(result.error)
    } else {
      const hexCentersLocal = curHexCenters.map((hexCenter, index) => {
        return { ...hexCenter, shopsCount: 0, idx: index }
      })

      // Check if lat & lng legit or not
      let shopData = result.data.data
        .filter(shop => {
          return (typeof shop.latitude === 'number') && (typeof shop.longitude === 'number')
        })
        .map(shop => transformShop(checkedShops, shop))

      shopData = getShopDisplayInHex(hexCentersLocal, shopData)
      setMarkers(shopData)
      dispatch(getShopList(shopData))
    }
  }

  

  const handleApiLoaded = (map, maps) => {
    setMapRef(map)
    setMapsRef(maps)
  }

  // const selectShopCheckin = (key, childProps) => {
  //   if (childProps.notClickable || !childProps.display) {
  //     return
  //   }

  //   mapRef.setCenter({ lat: childProps.lat, lng: childProps.lng })
  //   if (modalKey === null || modalKey !== key) {
  //     setModalVisible(true)
  //     setModalKey(key)
  //     const data = markers.find(marker => marker.key === key)
  //     data.position = { 'lat': childProps.lat, 'lng': childProps.lng }
  //     data.id = data.key
  //     setModalData(data)
  //   } else {
  //     setModalKey(null)
  //     setModalVisible(false)
  //   }
  // }
  const selectSpotCheckin = (key, spot) => {
    if (key) {
      mapRef.setCenter({ lat: spot.location.latitude, lng: spot.location.longitude })
      setModalData(spot);
      setModalKey(key);
      setModalVisible(true);
    }
    else {
      setModalKey(null)
      setModalVisible(false)
    }
  }

  const findMyLocation = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 0,
      maximumAge: 0
    };
    if (mapRef !== null) {
      mapRef.setCenter({ lat: currentPosition.lat, lng: currentPosition.lng })
      setMapPosition({ lat: currentPosition.lat, lng: currentPosition.lng })
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          setCurrentPosition(pos)
          dispatch(updateUserPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }))
          if (mapRef !== null) {
            mapRef.setCenter({ lat: currentPosition.lat, lng: currentPosition.lng })
            setMapPosition({ lat: currentPosition.lat, lng: currentPosition.lng })
          }
        },
        () => {
          console.error('Something wrong with GGMaps')
        },
        options
      )
    } else {
      // Browser doesn't support Geolocation
      console.error('Not support Geolocation')
    }
  }

  const getPolygonsPoint = (position = null) => {
    const curPos = !position ? currentPosition : position

    // prevent rerender again
    const r_lng = calculateRLng(curPos)
    const h = calculateH()
    // number of hexagon per side, must dividable with 8
    // caculate nearest point with user for hex render
    const cp = getBasePoint(curPos, r_lng, h)
    // caculate hex centers
    return constructCenters(cp, r_lng, h, NUMBER_HEX_PER_SIDE)
  }

  useEffect(() => {
    if (spots.length == 0) {
      const params = {
        playsheetID: props.playsheetID,
        playerId: props.playerId,
        playerBingoId: props.playerBingoId
      }
      dispatch(getListSpots(params))
    }
  }, [])

  useEffect(() => {
    const jumpToTop = () => {
      window.scroll({
        top: 0
      });
    }
    const changeHeight = () => {
      const mapDiv = document.getElementsByClassName('googleMap');
      const mapContainer = document.getElementsByClassName('samsung-br-map');
      if (mapDiv.length > 0 && mapContainer.length > 0) {
        mapDiv[0].style.height = window.innerHeight - 2 + 'px';
        mapContainer[0].style.height = window.innerHeight - 2 + 'px';
      }
    }
    const success = (position) => {
      const coordinate = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      setCurrentPosition(coordinate)
      dispatch(updateUserPosition(coordinate))
    }
    const error = (err) => {
      console.warn('ERROR(' + err.code + '): ' + err.message)
    }

    const options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0,
    }
    jumpToTop()
    changeHeight()
    window.addEventListener('resize', changeHeight)
    const watchPosition = navigator.geolocation.watchPosition(success, error, options)

    return () => {
      navigator.geolocation.clearWatch(watchPosition)
      window && window.removeEventListener('resize', changeHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (mapRef !== null) {
      mapRef.setCenter({ lat: currentPosition.lat, lng: currentPosition.lng })
      setMapPosition({ lat: currentPosition.lat, lng: currentPosition.lng })
      //renderHexs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef])

  // useEffect(() => {
  //   mapsRef && renderHexs()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mapsRef])

  // useEffect(() => {
  //   if (checkedShops.length && dataShops.length) {
  //     console.log('useEffect checkedShops')
  //     const _markers = markers.map(marker => {
  //       const completed = !!checkedShops.includes(marker.key);
  //       if (completed !== marker.completed) {
  //         return { ...marker, completed }
  //       }
  //       return marker
  //     })
  //     setMarkers(_markers)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [checkedShops, dataShops])

  useEffect(() => {
    if (navigator.geolocation) {
      const dis = calculateDistance(currentPosition, prevPosition)

      if (dis > DISTANCE_REFRESH_SHOP) {
        getShops(currentPosition)
        setPrevPosition(currentPosition)
        setModalVisible(false)
        setModalKey(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition])

  const mainBtnHandleClick = () => {
  };
  if (spots.length == 0) {
    return <Loading />;
  } else {
  
    return (<div
      className="googleMap"
      style={{
        width: '100%',
        position: 'relative',
        display: props.display,
        paddingBottom: '140px',
        // zIndex: props.zIndex
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: (process.env.NODE_ENV !== 'production') ? '' : `${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places`,
        }}
        center={{ lat: mapPosition.lat, lng: mapPosition.lng }}
        defaultZoom={17}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        options={() => ({
          // minZoom: 15,
          // maxZoom: 24,
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          clickableIcons: false,
          gestureHandling: "greedy"
        })}
      >

        {spots.map(spot => {
          const color = `rgb(${spot.design.red},${spot.design.green},${spot.design.blue})`;
          return (
            <MarkerIcon
              spot={spot}
              key={spot.id}
              lat={spot.location.latitude}
              lng={spot.location.longitude}
              filled={modalKey === spot.id}
              display={true}
              color={color}
              // shopId={marker.shop_id}
              // completed={marker.completed}
              //onClick={(key, value) => selectShopCheckin(key, value)}
              onClick={() => selectSpotCheckin(spot.id, spot)}
            />
          )
        })}

        <CurrentLocation
          key={'0'}
          notClickable={true}
          lat={currentPosition.lat}
          lng={currentPosition.lng}
        />
      </GoogleMapReact>

      <InfoMarkerModal
        visible={modalVisible}
        data={modalData}
        distance={calculateDistance(currentPosition, modalData.position)}
        playerBingoId={props.playerBingoId}
      />
      <CurrentLocationButton onClick={findMyLocation} modalVisible={modalVisible} />
    </div>
    )
  }
}

export default GoogleMap
