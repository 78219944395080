import React, { useState, useEffect } from 'react';
import GoBackHeader from '../../../components/headers/GoBackHeader';
import IconButtonShare from '../../../static/svg/IconButtonShare';
import IconGenreShrine from '../../../static/svg/IconGenreShrine';
import BingoSpotInfo from './BingoSpotInfo';
import './styles.scss';
import CopyRight from '../../../components/copyRights/CopyRight';
import CheckinButton from '../../../components/buttons/CheckinButton';
import CheckInModal from '../../../components/modals/CheckinModal';
import CheckinSuccessModal from '../../../components/modals/CheckinSuccessModal';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { checkinSpot, getSpotDetail, getSpotDetailSuccess, setCheckinSuccess, resetCheckinSpotFail } from '../../../redux/spot/action';
import { setCheckedSpot, setLastChecked, setReloadBingos, updateBingo, updateSheetDetail } from '../../../redux/bingo/action';
import { DEFAULT_IMAGE_IMG_PATH } from '../../../static/png';
import GenreIcon from '../../../components/genre/GenreIcon';
import ErrorModal from '../../../components/modals/ErrorModal';
import { canCheckin } from '../../../services/helper';
import { calculateDistance } from 'common/src/util/geo';
import { disableScroll } from '../../../services/helper';
import Loading from '../../../components/loading/Loading'
import { setNeedUpdateTotalTicket } from '../../../redux/user/action';
import { setNeedReloadHistory } from '../../../redux/bingoHistory/action';


const BingoSpotDetailScreen = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const spotParams = useParams();
  const spotDesign = spotParams.color;
  const showButtonCheckin = props.showBtnCheckin;
  const { spot, isCheckinSpot, checkinSuccess, actionCheckinFail } = useSelector((state) => state.spot);
  const image = spot.image ? spot.image : DEFAULT_IMAGE_IMG_PATH;
  const { checkedSpots } = useSelector((state) => state.bingo);
  const [display, setDisplay] = useState(0);
  const spotData = useSelector((state) => state.spot.spots.find((spot) => spot.id === spotParams.spotId));
  const { userPosition } = useSelector((state) => state.user);
  const playerId = window.localStorage.getItem('user_id');
  const { spots } = useSelector((state) => state.spot);
  const [loading, setLoading] = useState(true);
  const playerBingoId = props.match.params.playerBingoId;
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (spotData) {
      dispatch(getSpotDetailSuccess(spotData))
    } else {
      dispatch(getSpotDetail(spotParams.spotId));
    }
  }, [spotData])
  useEffect(() => {
    disableScroll(display)
  }, [display])
  const handleCheckinSpot = () => {
    const params = {
      playerId: playerId,
      bingoId: playerBingoId,
      spotId: spot.id,
      latitude: userPosition?.lat,
      longitude: userPosition?.lng,
    }
    dispatch(checkinSpot(params));
  }
  
  useEffect(() => {
    if (actionCheckinFail) {
      setDisplay(5);
    }
  })

  const updateAfterCheckin = () => {
    dispatch(setCheckinSuccess(false))
    dispatch(setReloadBingos(true))
    dispatch(setNeedUpdateTotalTicket(true))
    dispatch(setNeedReloadHistory(true))
    setIsDisable(true)
  }

  const goToChallenge = () => {
    updateAfterCheckin();
    history.push(`/bingo/${ playerBingoId }/challenge`);
  }

  const resetCheckinSpot = () => {
    dispatch(resetCheckinSpotFail());
    setDisplay(0);
  }

  useEffect(() => {
    if (checkinSuccess) {
      checkedSpots.push(spot.id);
      if (spotData)
        spotData.isCheckin = true;
      dispatch(setCheckedSpot(checkedSpots));
      dispatch(updateSheetDetail(true));
      dispatch(setLastChecked(spotData.id));
      setDisplay(2);
    }
  }, [checkinSuccess])

  useEffect(() => {
    if (spots.length > 0) {
      if(spotData == undefined) {
        history.push('/404');
      }
    }
  }, [spots])
  useEffect(() => {
    if (spot.id === undefined) {
      setLoading(true)
    } else if (spot.id === spotParams.spotId) {
      if (
        spot.isCheckin ||
        !canCheckin(calculateDistance(userPosition, {lat: spot.location?.latitude, lng: spot.location?.longitude}))
        ) {
        setIsDisable(true);
      }
      setLoading(false)
    }
  }, [spot])
  if (loading) {
    return <Loading />
  } else {
    return (
      <>
        <div className="bingo-spot-detail-screen">
          <GoBackHeader name="スポット情報" rightClick={() => { }} />
          <div className="bingo-spot-image">
            <img src={image} alt={spot.name} />
          </div>
          <div className="bingo-spot-header">
            <div className="header-icon"><GenreIcon spot={spot} fill={spotDesign} sizeCategory={'size01'} /></div>
            <div className="header-title">{spot.name}</div>
          </div>
          <div className="bingo-spot-content">
            <div className="bingo-spot-desc">
              {spot.details?.description}
            </div>
            {showButtonCheckin && <div className="bingo-spot-checkin">
              <CheckinButton name={spot.isCheckin ? "チェックイン済み" : isDisable ? "現在地から離れています" : "チェックインする"} isDisable={isDisable} onClick={() => { setDisplay(1) }} />
            </div>}
            <BingoSpotInfo spot={spot} url={spot.details?.url} showButtonCheckin={showButtonCheckin} />
          </div>
          <CopyRight />
        </div>
        {display === 1 && (
          <CheckInModal
            image={image}
            name={spot.name}
            isCheckinSpot={isCheckinSpot}
            onClose={() => setDisplay(0)}
            onClick={() => handleCheckinSpot()}
          />
        )}
        {display === 2 && (
          <CheckinSuccessModal
            spot={spot}
            onClose={() => {setDisplay(0); updateAfterCheckin()}}
            onClick={() => goToChallenge()}
            status={true}
          />
        )}
        {display === 5 && (
          <ErrorModal
            label1="チェックインが出来ませんでした。"
            label2="再度チェックインをお試しください"
            mainBtnLabel="チェックイン!"
            onClose={() => resetCheckinSpot()}
            maintBtnOnClick={() => handleCheckinSpot()}
          />
        )}
      </>
    )
  }
}
export default withRouter(BingoSpotDetailScreen)