import React from "react";

const IconGenreLandmark = (props) => {
  var svg;
  switch (props.sizeCategory ?? 1) {
    case 'size01':
      // 周辺地図/チェックイン画面
      svg = <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 28.212C14.2155 28.2105 15.404 28.5697 16.4153 29.244C17.4265 29.9183 18.2151 30.8774 18.681 32H26C22.1051 26.9629 19.1604 21.2584 17.31 15.166H8.69098C6.83983 21.2583 3.89482 26.9627 0 32H7.32001C7.78579 30.8775 8.57411 29.9184 9.58521 29.2441C10.5963 28.5698 11.7847 28.2106 13 28.212ZM12.059 18.677H13.942C14.7338 20.7482 15.6694 22.7614 16.742 24.702H9.25299C10.3275 22.7628 11.2652 20.7509 12.059 18.681V18.677ZM8.88 13.248C8.72408 13.2526 8.5688 13.2258 8.4234 13.1693C8.278 13.1127 8.14548 13.0276 8.03357 12.9189C7.92166 12.8103 7.83266 12.6802 7.77191 12.5366C7.71116 12.3929 7.67987 12.2385 7.67987 12.0825C7.67987 11.9265 7.71116 11.7721 7.77191 11.6284C7.83266 11.4847 7.92166 11.3547 8.03357 11.246C8.14548 11.1374 8.278 11.0522 8.4234 10.9957C8.5688 10.9392 8.72408 10.9124 8.88 10.917H9.85901L10.836 7.08398H15.165L16.142 10.917H17.121C17.277 10.9124 17.4322 10.9392 17.5776 10.9957C17.723 11.0522 17.8556 11.1374 17.9675 11.246C18.0794 11.3547 18.1684 11.4847 18.2291 11.6284C18.2899 11.7721 18.3212 11.9265 18.3212 12.0825C18.3212 12.2385 18.2899 12.3929 18.2291 12.5366C18.1684 12.6802 18.0794 12.8103 17.9675 12.9189C17.8556 13.0276 17.723 13.1127 17.5776 13.1693C17.4322 13.2258 17.277 13.2526 17.121 13.248H8.88ZM14.932 5.16595H11.069C10.7437 5.16596 10.4315 5.0373 10.2007 4.80798C9.96987 4.57867 9.83915 4.26731 9.83704 3.94196V3.16595C9.83915 2.8406 9.96987 2.52931 10.2007 2.29999C10.4315 2.07067 10.7437 1.94195 11.069 1.94196H12.05L12.759 0.161987C12.7787 0.114071 12.8121 0.0731003 12.8551 0.0442505C12.8981 0.0154007 12.9487 0 13.0005 0C13.0523 0 13.1029 0.0154007 13.1459 0.0442505C13.1889 0.0731003 13.2224 0.114071 13.242 0.161987L13.951 1.94196H14.932C15.2573 1.94195 15.5693 2.0707 15.8 2.30005C16.0306 2.52939 16.1612 2.84069 16.163 3.16595V3.94196C16.1612 4.26722 16.0306 4.57858 15.8 4.80792C15.5693 5.03727 15.2573 5.16596 14.932 5.16595Z" fill={props.fill}/>
      </svg>
      break;

    case 'size02':
      // 周辺地図チェックイン情報
      svg = <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 12.3427C6.56098 12.3421 7.10955 12.4992 7.57628 12.7942C8.04302 13.0892 8.40696 13.5089 8.62201 14H12C10.2024 11.7963 8.84324 9.30057 7.98923 6.63511H4.01122C3.15685 9.30051 1.79761 11.7962 0 14H3.37846C3.59344 13.5089 3.95728 13.0893 4.42394 12.7943C4.8906 12.4993 5.43909 12.3421 6 12.3427ZM5.5657 8.17119H6.43478C6.80019 9.07732 7.23201 9.9581 7.72708 10.8071H4.27061C4.76655 9.95873 5.19931 9.07853 5.5657 8.17292V8.17119ZM4.09846 5.79599C4.0265 5.79799 3.95483 5.78627 3.88772 5.76155C3.82062 5.73682 3.75945 5.69959 3.7078 5.65204C3.65615 5.60449 3.61507 5.54761 3.58704 5.48475C3.559 5.42189 3.54456 5.35432 3.54456 5.28608C3.54456 5.21783 3.559 5.15029 3.58704 5.08743C3.61507 5.02457 3.65615 4.96769 3.7078 4.92014C3.75945 4.87259 3.82062 4.83536 3.88772 4.81063C3.95483 4.7859 4.0265 4.77419 4.09846 4.77618H4.55031L5.00123 3.09924H6.99922L7.45017 4.77618H7.90202C7.97398 4.77419 8.04562 4.7859 8.11273 4.81063C8.17983 4.83536 8.24103 4.87259 8.29268 4.92014C8.34433 4.96769 8.3854 5.02457 8.41344 5.08743C8.44148 5.15029 8.45592 5.21783 8.45592 5.28608C8.45592 5.35432 8.44148 5.42189 8.41344 5.48475C8.3854 5.54761 8.34433 5.60449 8.29268 5.65204C8.24103 5.69959 8.17983 5.73682 8.11273 5.76155C8.04562 5.78627 7.97398 5.79799 7.90202 5.79599H4.09846ZM6.8917 2.26011H5.10878C4.95861 2.26011 4.81454 2.20382 4.70801 2.10349C4.60148 2.00317 4.54115 1.86695 4.54017 1.72461V1.38511C4.54115 1.24276 4.60148 1.10657 4.70801 1.00624C4.81454 0.905918 4.95861 0.849603 5.10878 0.849606H5.56153L5.88878 0.0708694C5.89785 0.0499061 5.91328 0.0319814 5.93312 0.0193596C5.95297 0.00673779 5.97632 0 6.00023 0C6.02413 0 6.04751 0.00673779 6.06735 0.0193596C6.0872 0.0319814 6.10263 0.0499061 6.11169 0.0708694L6.43892 0.849606H6.8917C7.04182 0.849603 7.18584 0.905933 7.2923 1.00627C7.39876 1.10661 7.459 1.2428 7.45986 1.38511V1.72461C7.459 1.86691 7.39876 2.00313 7.2923 2.10347C7.18584 2.2038 7.04182 2.26011 6.8917 2.26011Z" fill={props.fill}/>
      </svg>
      break;

    case 'size03':
      // ビンゴシート
      svg = <svg width="34" height="40" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 35.265C18.5894 35.2632 20.1437 35.7121 21.4661 36.5549C22.7886 37.3978 23.8197 38.5968 24.429 40H34C28.9067 33.7037 25.0559 26.5731 22.6362 18.9574H11.3651C8.9444 26.5729 5.09323 33.7034 0 40H9.57232C10.1814 38.5969 11.2123 37.398 12.5345 36.5551C13.8567 35.7122 15.4107 35.2632 17 35.265ZM15.7695 23.3463H18.2319C19.2672 25.9352 20.4907 28.4517 21.8934 30.8775H12.1001C13.5052 28.4535 14.7314 25.9386 15.7695 23.3512V23.3463ZM11.6123 16.56C11.4084 16.5657 11.2054 16.5322 11.0152 16.4616C10.8251 16.3909 10.6518 16.2845 10.5054 16.1487C10.3591 16.0128 10.2427 15.8503 10.1633 15.6707C10.0838 15.4911 10.0429 15.2981 10.0429 15.1031C10.0429 14.9081 10.0838 14.7151 10.1633 14.5355C10.2427 14.3559 10.3591 14.1934 10.5054 14.0575C10.6518 13.9217 10.8251 13.8153 11.0152 13.7447C11.2054 13.674 11.4084 13.6405 11.6123 13.6462H12.8925L14.1702 8.85498H19.8311L21.1088 13.6462H22.389C22.5929 13.6405 22.7959 13.674 22.9861 13.7447C23.1762 13.8153 23.3496 13.9217 23.4959 14.0575C23.6423 14.1934 23.7586 14.3559 23.8381 14.5355C23.9175 14.7151 23.9584 14.9081 23.9584 15.1031C23.9584 15.2981 23.9175 15.4911 23.8381 15.6707C23.7586 15.8503 23.6423 16.0128 23.4959 16.1487C23.3496 16.2845 23.1762 16.3909 22.9861 16.4616C22.7959 16.5322 22.5929 16.5657 22.389 16.56H11.6123ZM19.5265 6.45744H14.4749C14.0494 6.45745 13.6412 6.29663 13.3394 6.00998C13.0375 5.72333 12.8666 5.33414 12.8638 4.92744V3.95744C12.8666 3.55075 13.0375 3.16163 13.3394 2.87498C13.6412 2.58834 14.0494 2.42744 14.4749 2.42744H15.7577L16.6849 0.202484C16.7106 0.142589 16.7543 0.0913754 16.8105 0.0553131C16.8668 0.0192508 16.9329 0 17.0006 0C17.0684 0 17.1346 0.0192508 17.1908 0.0553131C17.2471 0.0913754 17.2908 0.142589 17.3165 0.202484L18.2436 2.42744H19.5265C19.9518 2.42744 20.3599 2.58838 20.6615 2.87506C20.9632 3.16174 21.1338 3.55086 21.1363 3.95744V4.92744C21.1338 5.33403 20.9632 5.72322 20.6615 6.0099C20.3599 6.29658 19.9518 6.45745 19.5265 6.45744Z" fill={props.fill}/>
      </svg>
      break;

    case 'size04':
      // パネル獲得時
      svg = <svg width="68" height="80" viewBox="0 0 68 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34 70.5299C37.1789 70.5263 40.2875 71.4242 42.9323 73.1099C45.5771 74.7956 47.6394 77.1935 48.8581 80H68C57.8134 67.4073 50.1117 53.1461 45.2723 37.9149H22.7303C17.8888 53.1458 10.1865 67.4068 0 80H19.1446C20.3628 77.1937 22.4246 74.7959 25.069 73.1102C27.7134 71.4245 30.8215 70.5265 34 70.5299ZM31.539 46.6925H36.4637C38.5345 51.8704 40.9814 56.9034 43.7868 61.7549H24.2001C27.0104 56.9071 29.4628 51.8773 31.539 46.7024V46.6925ZM23.2246 33.12C22.8168 33.1314 22.4107 33.0644 22.0304 32.9231C21.6502 32.7818 21.3036 32.5691 21.0109 32.2974C20.7182 32.0257 20.4854 31.7006 20.3265 31.3414C20.1677 30.9822 20.0858 30.5961 20.0858 30.2061C20.0858 29.8162 20.1677 29.4302 20.3265 29.071C20.4854 28.7118 20.7182 28.3868 21.0109 28.1151C21.3036 27.8434 21.6502 27.6306 22.0304 27.4893C22.4107 27.348 22.8168 27.2811 23.2246 27.2925H25.7851L28.3403 17.71H39.6623L42.2176 27.2925H44.7781C45.1859 27.2811 45.5919 27.348 45.9721 27.4893C46.3524 27.6306 46.6992 27.8434 46.9919 28.1151C47.2845 28.3868 47.5173 28.7118 47.6762 29.071C47.8351 29.4302 47.9169 29.8162 47.9169 30.2061C47.9169 30.5961 47.8351 30.9822 47.6762 31.3414C47.5173 31.7006 47.2845 32.0257 46.9919 32.2974C46.6992 32.5691 46.3524 32.7818 45.9721 32.9231C45.5919 33.0644 45.1859 33.1314 44.7781 33.12H23.2246ZM39.053 12.9149H28.9498C28.0988 12.9149 27.2824 12.5933 26.6787 12.02C26.075 11.4467 25.7332 10.6683 25.7276 9.85489V7.91489C25.7332 7.10149 26.075 6.32326 26.6787 5.74997C27.2824 5.17668 28.0988 4.85487 28.9498 4.85489H31.5154L33.3698 0.404968C33.4212 0.285178 33.5086 0.182751 33.621 0.110626C33.7335 0.0385016 33.8659 0 34.0013 0C34.1367 0 34.2692 0.0385016 34.3817 0.110626C34.4942 0.182751 34.5816 0.285178 34.6329 0.404968L36.4872 4.85489H39.053C39.9037 4.85488 40.7198 5.17676 41.3231 5.75012C41.9263 6.32349 42.2677 7.10172 42.2725 7.91489V9.85489C42.2677 10.6681 41.9263 11.4464 41.3231 12.0198C40.7198 12.5932 39.9037 12.9149 39.053 12.9149Z" fill={props.fill}/>
      </svg>
      break;
  }

  return (
    <>{ svg }</>
  );
};
export default IconGenreLandmark;
