import React, { useEffect } from 'react';
import './styles.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatDateEventJA, formatDateNoYearEventJA } from '../../helper/helper';
import moment from 'moment';
const HomeSlides = (props) => {
  const playerEvent = props.playerEvent;

  let startAt = playerEvent.startAt * 1000;
  let endAt = playerEvent.endAt * 1000;
  const startDate = moment(startAt).format(formatDateEventJA);
  const endDate = moment(endAt).format(formatDateNoYearEventJA);

  return (
    <>
      <div className="home-slides">
        <Swiper effect="fade" >
          <SwiperSlide>
            <div className="div-image">
              <img src={ playerEvent?.banners?.images?.topBanner } alt="" />
            </div>
            <div className="box-title"><h1>開催期間:{startDate}〜{endDate}</h1></div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  )
}
export default HomeSlides;