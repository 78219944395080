import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import StepTitle from './stepTitle'

export const StyleStepFour = styled.div`
  text-align: center;
  flex-direction:column;
  display:flex;
  height:100%;
`

const StepFour = (props) => {
  const widthImage = (window.innerWidth - 25)/2;
  const heightImage = widthImage*580/350;
  const divHeight = useRef(null);
  const [displayHeight, setDisplayHeight] = useState("")
  useEffect(()=>{ 
    if(heightImage > divHeight.current.clientHeight){
      setDisplayHeight(divHeight.current.clientHeight)
      
    }
    else{
      setDisplayHeight(heightImage)
    }
  })

  
  const listContent = [
    'チェックインに成功すると、',
    '区画と同じ色のタイルを獲得することができます。'
  ]
  return (
    <StyleStepFour>
      <StepTitle title="チェックインとは" listContent={listContent}/>
      <div ref={divHeight} style={{ marginBottom:'22px', flex:1,  maxHeight:displayHeight+"px" }}>
      <img className="image-tutorial" src="images/tutorialimg_3.png" alt="tutorialimg_3" style={{width:displayHeight*350/580+"px", height:displayHeight + "px",marginRight:'5px'}} />
      <img className="image-tutorial" src="images/tutorialimg_4.png" alt="tutorialimg_4" style={{width:displayHeight*350/580+"px", height:displayHeight + "px"}} />
      </div>
    </StyleStepFour>
  )
 
}

export default StepFour
