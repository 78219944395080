import React, { useEffect } from 'react';
import './styles.scss';
import MenuHeader from '../../components/headers/MenuHeader';
import CardName from './CardName';
import CardItem from './CardItem';
import { logout } from '../../services/authenticate';
import CopyRight from '../../components/copyRights/CopyRight';
import { backgroundGlay } from '../../helper/helper';
import { Link } from 'react-router-dom';
import {setLogin} from '../../redux/user/action'
import {useSelector, useDispatch} from 'react-redux'

const MenuScreen = () => {
  const dispatch    = useDispatch()
  const logoutApp = () => {
    dispatch(setLogin(false));
    logout();
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <MenuHeader name="メニュー" />
      <div className="menu-screen">
        <div className="box-menu">
          <CardName />
          <div className="box-item">
            {/* <Link to='/exchange-ticket'>
            <CardItem name="プレゼントの応募" />
            </Link> */}
            <Link to='/notification'>
              <CardItem name="お知らせ一覧" />
            </Link>
            <Link to='/bingo-log'>
              <CardItem name="ビンゴログ一覧" />
            </Link>
          </div>

          <div className="box-item">
            <Link to='/info-tabingo'>
              <CardItem name="TABINGOとは？" />
            </Link>
            {/* <Link to='/how-to-play'>
              <CardItem name="TABINGOの遊び方" />
            </Link> */}
            {/* <Link to='/problems'>
              <CardItem name="よくある質問" />
            </Link> */}
          </div>

          <div className="box-item">
            <a href='http://pocketculture.jp' target="_blank">
              <CardItem name="運営会社" />
            </a>
            <Link to='/term-of-use'>
              <CardItem name="利用規約" />
            </Link>
            <Link to='/privacy-protection'>
              <CardItem name="プレイバシーポリシー" />
            </Link>
          </div>

          <div className="box-item" style={{marginBottom:'56px'}}>
            <CardItem name="ログアウト" rightClick={logoutApp} />
          </div>
          <CopyRight backgroundColor={backgroundGlay} />
        </div>
      </div>
    </>
  )
}
export default MenuScreen;