import React from "react";

const IconBingoLineget = (props) => {
  return (
    <svg
      width={props.width ? props.width : '24'}
      height={props.height ? props.height : '28'}
      viewBox="0 0 24 28"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.45 10.974V9.449C21.45 4.239 17.21 0 12 0C6.79 0 2.551 4.239 2.551 9.449V10.975H0V25.529C0 26.893 1.106 28 2.47 28H21.53C22.895 28 24 26.893 24 25.528V10.974H21.45ZM14.605 24.906C14.728 25.297 14.545 25.621 14.196 25.621H9.804C9.454 25.621 9.271 25.297 9.395 24.906L10.642 19.527C9.534 19.014 8.762 17.894 8.762 16.59C8.762 14.802 10.211 13.351 12 13.351C13.788 13.351 15.238 14.802 15.238 16.59C15.238 17.893 14.467 19.013 13.358 19.527L14.605 24.906ZM17.59 10.974H6.41V9.449C6.41 6.367 8.918 3.86 12 3.86C15.083 3.86 17.59 6.367 17.59 9.449V10.974Z"
        fill={props.fill} />
    </svg>


  );
};
export default IconBingoLineget;
