import React from 'react'
import styled from 'styled-components'

export const StyleStepTittle = styled.div`
  text-align: center;
  .tabingo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 29px 0 24px 0;

    h2 {
      font-size: 21px;
      margin: 0 20px;
      line-height:17px;
    }

    img {
      width: 20px;
    }
  }

  .title {
    font-weight: 450;
    margin-bottom: 30px;
    line-height:24px;
    
    p {
      color: #273956;
      font-weight: 600;
      font-size:14px;
    }
  }
`

const StepTitle = (props) => {
  return (
    <StyleStepTittle>
      <div className="tabingo" style={props.isLast?{paddingBottom:'11px'}:null}>
        <img src="icon/rainbow_kirakira_L.svg" className="imgLeft" alt="rainbow_kirakira_L"/>
        <h2 className="color-blue">{props.title}</h2>
        <img src="icon/rainbow_kirakira_R.svg" className="imgRight" alt="rainbow_kirakira_R"/>
      </div>
      <div className="title" style={props.isLast?{marginBottom:'17px'}:null}>
        {props.listContent.map((content, index) => <p key={index}>{content}</p>)}
      </div>
    </StyleStepTittle>
  )
}

export default StepTitle
