import React from 'react';
import IconGenreShrine from '../../static/svg/IconGenreShrine';
import IconGenreTemple from '../../static/svg/IconGenreTemple';
import IconGenreFood from '../../static/svg/IconGenreFood';
import IconGenreSouvenir from '../../static/svg/IconGenreSouvenir';
import IconGenreHistory from '../../static/svg/IconGenreHistory';
import IconGenreScenicspots from '../../static/svg/IconGenreScenicspots';
import IconGenreBuilding from '../../static/svg/IconGenreBuilding';
import IconGenreLandmark from '../../static/svg/IconGenreLandmark';
import { mainColor } from '../../helper/helper';

const GenreIcon = (props) => {
  const spot = props.spot;
  const fill = props.fill ? props.fill : null;
  const sizeCategory = props.sizeCategory
  if (!spot)
    return null;
  const genre = spot.genre;
  const color = spot.design ? `rgb(${spot.design?.red},${spot.design?.green},${spot.design?.blue})` : mainColor;
  let icon_genre;
  switch (genre) {
    
    case '神社':
      icon_genre = <IconGenreShrine fill={fill ? fill : color} sizeCategory={sizeCategory} />;
      break;
    case '仏閣':
      icon_genre = <IconGenreTemple fill={fill ? fill : color} sizeCategory={sizeCategory} />;
      break;
    case '食事':
      icon_genre = <IconGenreFood fill={fill ? fill : color} sizeCategory={sizeCategory} />;
      break;
    case 'お土産':
      icon_genre = <IconGenreSouvenir fill={fill ? fill : color} sizeCategory={sizeCategory} />;
      break;
    case '歴史':
      icon_genre = <IconGenreHistory fill={fill ? fill : color} sizeCategory={sizeCategory} />;
      break;
    case '景勝地':
      icon_genre = <IconGenreScenicspots fill={fill ? fill : color} sizeCategory={sizeCategory} />;
      break;
    case 'ランドマーク':
      icon_genre = <IconGenreLandmark fill={fill ? fill : color} sizeCategory={sizeCategory} />;
      break;
    case '建物':
      icon_genre = <IconGenreBuilding fill={fill ? fill : color} sizeCategory={sizeCategory} />;
      break;
  }

  return (
    <>
      {icon_genre}
    </>
  )
}
export default GenreIcon;