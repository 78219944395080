export const BASE_POINT = { lat: 35.685, lng: 139.78 };
export const RADIUS = 100; // radius of HEX
export const NUMBER_HEX_PER_SIDE = 16; // number of HEX in per side
export const DISTANCE_REFRESH_SHOP = 1500; // met
export const RANGE_GET_SHOP = 2000; //met
export const DISTANCE_AVAILABLE_CHECK = 100; // met
export const DISTANCE_AVAILABLE_CHECK_DEV = 100; // met


export const COLORS = [
  { icon: 'blue', hex: '#61BBFD' },
  { icon: 'red', hex: '#FF9DA2' },
  { icon: 'orange', hex: '#FFB341' },
  { icon: 'green', hex: '#C3FF86' },
];

/**
 * return index color (in COLORS) of the HEX
 * @param {number} idx ordinal number of HEX in range coverage
 * @returns {number}
 */
export function getRuleByIdx(idx) {
  let a = idx % 8;
  // blue, red, orange, blue, orange, green, red, green
  return a === 0 || a === 3
    ? 0
    : a === 1 || a === 6
    ? 1
    : a === 2 || a === 4
    ? 2
    : 3;
}

export function genHexCol(cp1, r, h, deviation, numberHexPerSide) {
  const maxNumberHexPerSide = 2 * numberHexPerSide;
  const fp = {
    lat: cp1.lat - ((3 * r) / 2) * numberHexPerSide,
    lng: cp1.lng - h,
    color: COLORS[getRuleByIdx(deviation)].hex,
  };
  const cps = [fp];

  for (let index = 1; index < maxNumberHexPerSide; index++) {
    const cp = cps[index - 1];
    const new_cp = {
      lat: cp.lat + (3 * r) / 2,
      lng: index % 2 === 0 ? cp.lng - h : cp.lng + h,
      color: COLORS[getRuleByIdx(index + deviation)].hex,
    };
    cps.push(new_cp);
  }
  return cps;
}

export function genHexRow(cp1, r, h, numberHexPerSide) {
  const maxNumberHexPerSide = 2 * numberHexPerSide;
  const fp = { lat: cp1.lat, lng: cp1.lng - h * 2 * numberHexPerSide };
  const cps = [{ ...fp, color: COLORS[getRuleByIdx(0)].hex }];

  for (let index = 1; index < maxNumberHexPerSide; index++) {
    const cp = cps[index - 1];
    const new_cp = {
      lat: cp.lat,
      lng: cp.lng + h * 2,
      color: COLORS[getRuleByIdx(index)].hex,
    };
    cps.push(new_cp);
  }
  return cps;
}

/**
 * find the center point of range coverage
 * @param currentPosition
 * @param r
 * @param h
 * @returns {{lng: number, lat: number}}
 */
export function getBasePoint(currentPosition, r, h) {
  let lng_step = (currentPosition.lng - BASE_POINT.lng) / (2 * h);
  let new_base_lng_step = Math.trunc(lng_step / 8) * 8;
  let new_base_lng = BASE_POINT.lng + new_base_lng_step * (2 * h);
  let base_lat = lng_step % 2 === 0 ? BASE_POINT.lat - h : BASE_POINT.lat + h;
  let lat_step = (currentPosition.lat - base_lat) / (1.5 * r);
  let new_base_lat_step = Math.trunc(lat_step / 8) * 8;
  let new_base_lat = BASE_POINT.lat + new_base_lat_step * (1.5 * r);

  return { lat: new_base_lat, lng: new_base_lng };
}

/**
 * Build HEX from center point
 * @param centerPoint
 * @param r
 * @param h
 * @returns {({lng: *, lat}|{lng, lat}|{lng: *, lat})[]}
 */
export function constructHexagon(centerPoint, r, h) {
  h = h * 0.98;
  r = r * 0.98;
  const fp1 = { lat: centerPoint.lat + r, lng: centerPoint.lng };
  const fp2 = { lat: centerPoint.lat + r / 2, lng: centerPoint.lng - h };
  const fp3 = { lat: centerPoint.lat - r / 2, lng: centerPoint.lng - h };
  const fp4 = { lat: centerPoint.lat - r, lng: centerPoint.lng };
  const fp5 = { lat: centerPoint.lat - r / 2, lng: centerPoint.lng + h };
  const fp6 = { lat: centerPoint.lat + r / 2, lng: centerPoint.lng + h };
  return [fp1, fp2, fp3, fp4, fp5, fp6];
}

/**
 * from one center point of range coverage return the center point of HEX
 * @param centerPoint. the center point nearest player
 * @param r
 * @param h
 * @param numberHexPerSide
 * @returns {[]}
 */
export function constructCenters(centerPoint, r, h, numberHexPerSide) {
  const r_center = genHexRow(centerPoint, r, h, numberHexPerSide);
  let centers = [];

  for (let index = 0; index < r_center.length; index++) {
    const element = r_center[index];
    const c_center = genHexCol(
      element,
      r,
      h,
      getRuleByIdx(index),
      numberHexPerSide
    );
    centers = centers.concat(c_center);
  }

  return centers;
}

/**
 *
 * @param currentPosition. EX: {lat: number, lng: number}
 * @returns {number}
 */
export function calculateRLng(currentPosition) {
  return (
    (RADIUS / 1000 / 111.111) * Math.cos((currentPosition.lat / 180) * Math.PI)
  );
}

export function calculateR() {
  return RADIUS / 1000 / 111.111;
}

export function calculateH() {
  return (Math.sqrt(3) / 2) * calculateR();
}

/**
 * calculate limit of range coverage
 * @param centerPoint: {lat: number, lng: number}
 * @returns {{lat_max: *, lat_min: number, lng_max: *, lng_min: number}}
 */
export function calculateLimitArea(centerPoint) {
  const rLng = calculateRLng(centerPoint);
  const h = calculateH();

  return {
    lat_min: centerPoint.lat - (NUMBER_HEX_PER_SIDE - 7) * 1.5 * rLng,
    lat_max: centerPoint.lat + (NUMBER_HEX_PER_SIDE - 7) * 1.5 * rLng,
    lng_min: centerPoint.lng - (NUMBER_HEX_PER_SIDE - 7) * (2 * h),
    lng_max: centerPoint.lng + (NUMBER_HEX_PER_SIDE - 7) * (2 * h),
  };
}
