import React, { useEffect, useState } from 'react';
import CopyRight from '../../components/copyRights/CopyRight';
import MainGoBackHeader from '../../components/headers/MainGoBackHeader';
import BingoSpotItem from './BingoSpotItem';
import { HEADER_TABBAR_COPYRIGHT_HEIGHT } from '../../helper/helper';
import { useDispatch, useSelector } from 'react-redux';
import { setSpotButtonCheckin } from '../../redux/app/action';
import './styles.scss';
import { getListSpots } from '../../redux/spot/action';
import Loading from '../../components/loading/Loading';
import IconDifficulty from '../../static/svg/IconDifficulty';
import { starColor } from '../../helper/helper';
import { useHistory, withRouter } from 'react-router-dom';
import { getBingosSelect } from '../../redux/bingo/action';
import { getPlayerEvents } from '../../redux/event/action';

const BingoSpotScreen = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [difficult, setdifficult] = useState(1);
  const [isloading, setidloading] = useState(true);
  const { playerEvent } = useSelector((state) => state.event);
  const { spots, isGettingSpots } = useSelector((state) => state.spot);
  const playsheetID = window.localStorage.getItem('playsheetID')
  const bingoData = useSelector((state) => state.bingo.bingos.find((bingo) => bingo.id === playsheetID));
  const playerBingoId = props.match.params.playerBingoId;

  useEffect(() => {
    if(!playsheetID) {
      history.push('/select-bingo')
    } else {
      if (!bingoData) {
        if(!playerEvent.id) {
          dispatch(getPlayerEvents({ playerId: window.localStorage.getItem('user_id') }))
          if (playerEvent.id) {
            dispatch(getBingosSelect({ playerId: window.localStorage.getItem('user_id'), playerEventId: playerEvent.id }))
            setdifficult(bingoData?.difficult);
          }
        } else {
          dispatch(getBingosSelect({ playerId: window.localStorage.getItem('user_id'), playerEventId: playerEvent.id }))
          setdifficult(bingoData?.difficult);
        }
      } else {
        setdifficult(bingoData?.difficult);
      }
    }
  }, [playerEvent])
  useEffect(() => {
    if (spots.length === 0) {
      const params = {
        playsheetID: window.localStorage.getItem('playsheetID'),
        playerId: window.localStorage.getItem('user_id'),
        playerBingoId: playerBingoId
      }
      dispatch(getListSpots(params))
    }
  }, [])

  useEffect(() => {
    if (spots.length > 0 && bingoData) {
      setdifficult(bingoData?.difficult);
      setidloading(false)
    } else {
      setidloading(true)
    }
  }, [spots, bingoData])

  const renderDificult = () => {
    return (
      <>
        <IconDifficulty fill={difficult >= 1 ? starColor : null} />
        <IconDifficulty fill={difficult >= 2 ? starColor : null} />
        <IconDifficulty fill={difficult == 3 ? starColor : null} />
      </>
    )
  }
  return (
    <>
      <div className="bingo-spot-screen" >
        <MainGoBackHeader name="ビンゴスポット" />
        {isloading && <Loading />}
        {!isloading && (
          <>
            <div className="bingo-spot-list" style={{ minHeight: window.innerHeight - HEADER_TABBAR_COPYRIGHT_HEIGHT + "px" }}>
              <div className="bingo-info">
                <img src={bingoData?.images?.header} alt="img" />
                <div className="title">{bingoData?.name}</div>
                <div className="row-dificult">
                  <div className="text-left">難易度</div>
                  <div className="text-right">{renderDificult()}</div>
                </div>
                <div className="row-area">
                  <div className="text-left">エリア</div>
                  <div className="text-right">{bingoData?.areas?.name}</div>
                </div>
                <div className="description">
                  {bingoData?.description}
                </div>
              </div>
              {spots && spots.map((item) => (
                <BingoSpotItem spot={item} playerBingoId={ playerBingoId }/>
              ))}
            </div>
            <CopyRight />
          </>
        )}
      </div>
    </>
  )
}
export default withRouter(BingoSpotScreen);
