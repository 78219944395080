import React from 'react';
import './styles.scss';
import { HEADER_TABBAR_COPYRIGHT_HEIGHT } from '../../helper/helper';

const ExchangeTicketBottom = () => {
  const TOP_HEIGHT = 192;
  return (
    <>
      <div className="bingo-list-bottom" style={{ minHeight: window.innerHeight - TOP_HEIGHT - HEADER_TABBAR_COPYRIGHT_HEIGHT + "px" }}>
      <div className="center">申し訳ありません。ただいま開発中です</div>
      </div>
    </>
  )
}
export default ExchangeTicketBottom;