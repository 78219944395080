import React, { useRef, useLayoutEffect, useState } from 'react';
import { DEFAULT_WIDTH } from '../../../../helper/helper';
import HexagonItem from './HexagonItem';
import './styles.scss'

const size = DEFAULT_WIDTH;
let widthItem = 70;
let heightItem = 80;
let defaultMargin = -20;

const BingoChallengeTop = (props) => {
  const panels = props.sheetDetail?.panels;
  const playerBingoId = props.sheetDetail?.playerBingoId;
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });
  const sheetDetail = props.sheetDetail;
  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth / size * widthItem,
        height: targetRef.current.offsetWidth / size * heightItem,
        margin: targetRef.current.offsetWidth / size * defaultMargin
      });
    }
  }, []);

  let yellowBorder = [];
  sheetDetail && sheetDetail.clearLines.forEach(clearLine => {
    clearLine.completeAt && clearLine.panelIds.forEach(panelId => {
      yellowBorder.push(panelId);
    })
  });

  return (
    <>
      <div ref={targetRef} className="bingo-challenge-top">
        <div className="bingo-challenge-shadow"></div>
        <div className="row">
          {panels && panels.slice(0, 3).map((panel, key) => (
            <HexagonItem bingoId={playerBingoId}  panel={panel} key={key} width={dimensions.width} height={dimensions.height} isClearFull={ yellowBorder.includes(panel.id) }/>
          ))}
        </div>
        <div className="row" style={{ marginTop: dimensions.margin + 'px' }}>
          {panels && panels.slice(3, 7).map((panel, key) => (
            <HexagonItem bingoId={playerBingoId}  panel={panel} key={key} width={dimensions.width} height={dimensions.height} isClearFull={ yellowBorder.includes(panel.id) } />
          ))}
        </div>
        <div className="row" style={{ marginTop: dimensions.margin + 'px' }}>
          {panels && panels.slice(7, 9).map((panel, key) => (
            <HexagonItem bingoId={playerBingoId}  panel={panel} key={key} width={dimensions.width} height={dimensions.height} isClearFull={ yellowBorder.includes(panel.id) } />
          ))}
          {panels && <HexagonItem bingoId={playerBingoId}  panel = {panels[9]} width={dimensions.width} height={dimensions.height} isCenter={true} isClearFull={ yellowBorder.includes(10) } />}
          {panels && panels.slice(10, 12).map((panel, key) => (
            <HexagonItem bingoId={playerBingoId}  panel={panel} key={key} width={dimensions.width} height={dimensions.height} isClearFull={ yellowBorder.includes(panel.id) }/>
          ))}
        </div>
        <div className="row" style={{ marginTop: dimensions.margin + 'px' }}>
          {panels && panels.slice(12, 16).map((panel, key) => (
            <HexagonItem bingoId={playerBingoId}  panel={panel} key={key} width={dimensions.width} height={dimensions.height} isClearFull={ yellowBorder.includes(panel.id) }/>
          ))}
        </div>
        <div className="row" style={{ marginTop: dimensions.margin + 'px' }}>
          {panels && panels.slice(16, 19).map((panel, key) => (
            <HexagonItem bingoId={playerBingoId}  panel={panel} key={key} width={dimensions.width} height={dimensions.height} isClearFull={ yellowBorder.includes(panel.id) }/>
          ))}
        </div>
      </div>
    </>
  )
}
export default BingoChallengeTop;