import React from 'react';
import { useHistory } from 'react-router';
import IconButtonChange from '../../static/svg/IconButtonChange';

const MainHeader = (props)=>{
  const history = useHistory();
  const isGettingData = props.isGettingData;
  const handleClick = () => {
    if (props.targetUrl == '/select-bingo') {
      history.push('/select-bingo');
    } else {
      history.goBack()
    }
  }

  return(
    <>
      <div className="main-header">
        <div className="back-icon" onClick={handleClick}>
          <IconButtonChange fill="#FFFFFF" />
        </div>
        {isGettingData ? (<i className="fa fa-refresh fa-spin" />) : (<h1 className="title">{props.name}</h1>)}
      </div>
    </>
  )
}
export default MainHeader;