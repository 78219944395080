import { detailPlayer } from 'common/src/api/player'
import { listPointHistory } from 'common/src/api/playerPointHistory'
import { formatNumber } from 'common/src/util/geo'
import { getLocationFromAddress } from 'common/src/util/helper'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateBingoHistory } from '../../redux/bingoHistory/action'
import { getUserInfo } from '../../redux/user/action'
import { serverApi } from '../../services/serverApi'
import JumpTopButton from '../../components/buttons/jump_top_button/JumpTopButton'
import Tutorial from '../tutorial/tutorial'
import HomeTab from './tabs/HomeTab';
import './styles.scss';
import HomeHeader from '../../components/headers/HomeHeader';
import EventBox from './eventBox/EventBox'
import { getNotifications } from '../../redux/notification/action'
import Loading from '../../components/loading/Loading';
import { getPlayerEvents } from '../../redux/event/action';
import { useHistory } from 'react-router-dom';
import { logout } from '../../services/authenticate'

const HomeScreen = () => { 
  const history = useHistory();

  if (!window.localStorage.getItem("showTutorial")) {
    history.push('/tutorial');
  }

  const dispatch = useDispatch();
  const { notifications, isCheckNotificaiton } = useSelector((state) => state.notification);
  const { isCheckUser, userInfo } = useSelector((state) => state.user)
  const { playerEvent, isGettingEventPlayer } = useSelector((state) => state.event);
  const needUpdate = useSelector(
    (state) => state.user.userInfo.need_update,
  )
  const playerId = window.localStorage.getItem('user_id')

  useEffect(() => {
      if (typeof userInfo.id === 'undefined') {
        getInfoUser(playerId)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[playerId])

  const getInfoUser = async (playerId) => {
    if (!playerId) {
      return;
    }
    const result = await serverApi(detailPlayer(playerId))
   
    if (result.data) {
      const player = result.data.data
      // set user info to redux
      dispatch(getUserInfo(player))
    }
  }

  useEffect(() => {
    if (isCheckUser) {
      if (notifications.length <= 0) {
        getNotificationsList()
      }
      if (isCheckNotificaiton && playerEvent.length <= 0) {
        dispatch(getPlayerEvents({ playerId: playerId }));
      }
    }
  },[isCheckUser, playerId, isCheckNotificaiton])

  const getNotificationsList = async (size = 6, page = 1) => {
    await dispatch(getNotifications({
      size:size, page: page 
    }))
  }

  const jumpToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: 0,
    })
  }

  useEffect(() => {
    jumpToTop()
  }, [])

  return (
    <React.Fragment>
      <div>
        { window.localStorage.getItem("showTutorial") ? <HomeHeader /> : null }
        {!isGettingEventPlayer ? <Loading /> :
          <>
            <div className="home-screen">
              <div className="home-screen-top">
                <EventBox playerId={playerId} />
                <div className="town-image"></div>
              </div>
              <div className="home-screen-content" >
                <HomeTab />
              </div>
            </div>
          </>}

      </div>

    </React.Fragment>
  )
}

export default HomeScreen;
