import React from "react";

const IconGenreBuilding = (props) => {
  var svg;
  switch (props.sizeCategory ?? 1) {
    case 'size01':
      // 周辺地図/チェックイン画面
      svg = <svg width="25.26" height="30" viewBox="0 0 25.26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1053 8.52631V6.6316H20.2105V4.73684L12.8005 0L5.3913 4.73135V6.62606H3.49654V8.52081H0V30H9.47368V23.6842H15.7895V30H25.2632V8.52631H22.1053ZM6.31579 26.8469H3.15789V23.689H6.31579V26.8469ZM6.31579 20.5311H3.15789V17.3732H6.31579V20.5311ZM6.31579 14.2153H3.15789V11.0574H6.31579V14.2153ZM11.3684 20.5311H9.47368V17.3732H11.3684V20.5311ZM11.3684 14.2153H9.47368V11.0574H11.3684V14.2153ZM10.59 6.32056C10.59 5.88336 10.7196 5.45597 10.9625 5.09245C11.2054 4.72893 11.5506 4.44557 11.9546 4.27826C12.3585 4.11095 12.803 4.0672 13.2318 4.15249C13.6606 4.23779 14.0545 4.44832 14.3636 4.75747C14.6728 5.06661 14.8833 5.4605 14.9686 5.8893C15.0539 6.3181 15.0101 6.76254 14.8428 7.16646C14.6755 7.57038 14.3921 7.91561 14.0286 8.15851C13.6651 8.4014 13.2377 8.53108 12.8005 8.53108C12.5098 8.53108 12.222 8.47372 11.9535 8.36233C11.685 8.25095 11.4411 8.08769 11.2357 7.88192C11.0304 7.67615 10.8677 7.43192 10.7569 7.16318C10.6461 6.89444 10.5894 6.60648 10.59 6.31579V6.32056ZM15.7895 20.5311H13.8947V17.3732H15.7895V20.5311ZM15.7895 14.2153H13.8947V11.0574H15.7895V14.2153ZM22.1053 26.8469H18.9474V23.689H22.1053V26.8469ZM22.1053 20.5311H18.9474V17.3732H22.1053V20.5311ZM22.1053 14.2153H18.9474V11.0574H22.1053V14.2153Z" fill={props.fill}/>
      </svg>
      break;

    case 'size02':
      // 周辺地図チェックイン情報
      svg = <svg width="11.79" height="14" viewBox="0 0 11.79 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3158 3.97894V3.09475H9.43157V2.21053L5.97357 0L2.51594 2.20796V3.09216H1.63172V3.97638H0V14H4.42105V11.0526H7.36842V14H11.7895V3.97894H10.3158ZM2.94737 12.5285H1.47368V11.0549H2.94737V12.5285ZM2.94737 9.58117H1.47368V8.10749H2.94737V9.58117ZM2.94737 6.63381H1.47368V5.16012H2.94737V6.63381ZM5.30525 9.58117H4.42105V8.10749H5.30525V9.58117ZM5.30525 6.63381H4.42105V5.16012H5.30525V6.63381ZM4.942 2.94959C4.942 2.74557 5.00249 2.54612 5.11584 2.37648C5.22919 2.20684 5.3903 2.0746 5.5788 1.99652C5.76729 1.91844 5.97472 1.89803 6.17483 1.93783C6.37493 1.97763 6.55875 2.07588 6.70302 2.22015C6.84729 2.36442 6.94553 2.54823 6.98534 2.74834C7.02514 2.94845 7.0047 3.15585 6.92662 3.34435C6.84855 3.53284 6.71633 3.69395 6.54669 3.8073C6.37705 3.92065 6.1776 3.98117 5.97357 3.98117C5.83792 3.98117 5.70358 3.9544 5.57828 3.90242C5.45298 3.85044 5.33916 3.77425 5.24334 3.67823C5.14752 3.5822 5.07158 3.46823 5.01987 3.34282C4.96816 3.21741 4.94171 3.08302 4.942 2.94737V2.94959ZM7.36842 9.58117H6.4842V8.10749H7.36842V9.58117ZM7.36842 6.63381H6.4842V5.16012H7.36842V6.63381ZM10.3158 12.5285H8.8421V11.0549H10.3158V12.5285ZM10.3158 9.58117H8.8421V8.10749H10.3158V9.58117ZM10.3158 6.63381H8.8421V5.16012H10.3158V6.63381Z" fill={props.fill}/>
      </svg>
      break;

    case 'size03':
      // ビンゴシート
      svg = <svg width="32" height="38" viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28 10.8V8.40002H25.6V6L16.214 0L6.82898 5.99304V8.39301H4.42896V10.793H0V38H12V30H20V38H32V10.8H28ZM8 34.006H4V30.006H8V34.006ZM8 26.006H4V22.006H8V26.006ZM8 18.006H4V14.006H8V18.006ZM14.4 26.006H12V22.006H14.4V26.006ZM14.4 18.006H12V14.006H14.4V18.006ZM13.414 8.00604C13.414 7.45225 13.5782 6.9109 13.8859 6.45044C14.1935 5.98998 14.6308 5.63105 15.1425 5.41913C15.6541 5.2072 16.2171 5.15179 16.7603 5.25983C17.3034 5.36787 17.8023 5.63454 18.1939 6.02612C18.5855 6.41771 18.8522 6.91663 18.9602 7.45978C19.0682 8.00292 19.0128 8.56588 18.8008 9.07751C18.5889 9.58915 18.23 10.0264 17.7696 10.3341C17.3091 10.6418 16.7678 10.806 16.214 10.806C15.8458 10.806 15.4812 10.7334 15.1411 10.5923C14.8009 10.4512 14.492 10.2444 14.2319 9.98376C13.9719 9.72312 13.7657 9.41377 13.6254 9.07336C13.485 8.73296 13.4132 8.36821 13.414 8V8.00604ZM20 26.006H17.6V22.006H20V26.006ZM20 18.006H17.6V14.006H20V18.006ZM28 34.006H24V30.006H28V34.006ZM28 26.006H24V22.006H28V26.006ZM28 18.006H24V14.006H28V18.006Z" fill={props.fill}/>
      </svg>
      break;

    case 'size04':
      // パネル獲得時
      svg = <svg width="64" height="76" viewBox="0 0 64 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M56 21.6V16.8001H51.2V12L32.428 0L13.658 11.9861V16.786H8.85791V21.5861H0V76H24V60H40V76H64V21.6H56ZM16 68.0121H8V60.0121H16V68.0121ZM16 52.0121H8V44.0121H16V52.0121ZM16 36.0121H8V28.0121H16V36.0121ZM28.7999 52.0121H24V44.0121H28.7999V52.0121ZM28.7999 36.0121H24V28.0121H28.7999V36.0121ZM26.828 16.0121C26.828 14.9045 27.1564 13.8218 27.7717 12.9009C28.3871 11.98 29.2616 11.2621 30.2849 10.8383C31.3082 10.4144 32.4342 10.3036 33.5205 10.5197C34.6068 10.7357 35.6046 11.2691 36.3878 12.0523C37.171 12.8354 37.7043 13.8333 37.9204 14.9196C38.1365 16.0059 38.0255 17.1318 37.6017 18.155C37.1778 19.1783 36.4601 20.0529 35.5392 20.6682C34.6183 21.2836 33.5356 21.6121 32.428 21.6121C31.6916 21.6121 30.9623 21.4668 30.2821 21.1846C29.6019 20.9024 28.984 20.4888 28.4639 19.9675C27.9437 19.4463 27.5315 18.8275 27.2507 18.1467C26.97 17.4659 26.8264 16.7364 26.828 16V16.0121ZM40 52.0121H35.2V44.0121H40V52.0121ZM40 36.0121H35.2V28.0121H40V36.0121ZM56 68.0121H48V60.0121H56V68.0121ZM56 52.0121H48V44.0121H56V52.0121ZM56 36.0121H48V28.0121H56V36.0121Z" fill={props.fill}/>
      </svg>
      break;
  }

  return (
    <>{ svg }</>
  );
};
export default IconGenreBuilding;
