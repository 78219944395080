import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import StepTitle from './stepTitle'

export const StyleStepFive = styled.div`
text-align: center;
flex-direction:column;
display:flex;
height:100%;
`

const StepFive = (props) => {
  const widthImage = (window.innerWidth - 25)/2;
  const heightImage = widthImage*580/350;
  const divHeight = useRef(null);
  const [displayHeight, setDisplayHeight] = useState("")
  useEffect(()=>{ 
    if(heightImage > divHeight.current.clientHeight){
      setDisplayHeight(divHeight.current.clientHeight)
      
    }
    else{
      setDisplayHeight(heightImage)
    }
  })
  const listContent = [
    'BINGOCHALLENGEのページでは、',
    '獲得したタイルが埋まっていきます！'
  ]

  return (
    <StyleStepFive>
      <StepTitle title="BINGOCHALLENGEとは" listContent={listContent}/>
      <div ref={divHeight} style={{ marginBottom:'22px', flex:1,  maxHeight:displayHeight+"px" }}>
        <img className="image-tutorial" src="images/tutorialimg_5.png" alt="tutorialimg_5" style={{ width: displayHeight*350/580+"px",height: displayHeight+"px" }} />
      </div>
    </StyleStepFive>
  )
}

export default StepFive
