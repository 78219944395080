import React, { useEffect } from 'react';
import './styles.scss';
import BingoSelectTop from './BingoSelectTop';
import BingoListBottom from './BingoListBottom';
import QuestionHeader from '../../../components/headers/QuestionHeader';
import CopyRight from '../../../components/copyRights/CopyRight';
import { useDispatch, useSelector } from 'react-redux';
import { getPlayerEvents } from '../../../redux/event/action';
import Loading from '../../../components/loading/Loading';
import { backgroundGlay } from '../../../helper/helper';

const BingoSelectScreen = () => {
    const playerId = window.localStorage.getItem('user_id');
    const dispatch = useDispatch();
    useEffect(() => {
        if (!playerId) {
            return;
        }
        dispatch(getPlayerEvents({ playerId: playerId }))
    }, [])
    const { isGettingEvent } = useSelector((state) => state.event);


    return (
        <>
            <QuestionHeader name="横浜ビンゴチャレンジ" />
            {isGettingEvent ? <Loading /> :
                <div className="bingo-select-screen">
                    <BingoSelectTop />
                    <BingoListBottom />
                    <CopyRight backgroundColor={backgroundGlay} />
                </div>
            }

        </>
    )
}
export default BingoSelectScreen;