import { takeLatest, put } from '@redux-saga/core/effects';
import { serverApi } from '../../services/serverApi';
import { actionTypes, getListEventsFail, getListEventsSuccess, getPlayerEventsFail, getPlayerEventsSuccess } from './action';
import { getListEvents, getPlayerEvent } from 'common/src/api/event';

function* getListEventsSaga() {
  try {
    const res = yield serverApi(getListEvents());
    if (res.status === 200) {
      const data = res.data.data;
      yield put(getListEventsSuccess(data));
    } else {
      yield put(getListEventsFail());
    }
  } catch (error) {
    yield put(getListEventsFail());
  }
}

function* getPlayerEventSaga(action) {
  try {
    const playerId = localStorage.getItem('user_id');
    const res = yield serverApi(getPlayerEvent(playerId));
    if (res.status === 200) {
      const data = res.data.data;
      window.localStorage.setItem("playerEventId", res.data.data.id);
      yield put(getPlayerEventsSuccess(data));
    } else {
      yield put(getPlayerEventsFail());
    }
  } catch (error) {
    yield put(getPlayerEventsFail());
  }
}

export function* watchGetListEvents() {
  yield takeLatest(actionTypes.GET_LIST_EVENTS, getListEventsSaga);
}

export function* watchGetPlayerEvent() {
  yield takeLatest(actionTypes.GET_PLAYER_EVENT, getPlayerEventSaga);
}
