import React, { useEffect } from 'react';
import HomeSlides from '../../../components/eventSlides/HomeSlides';
import { ITEM_TICKET_YOKOHAMA_IMG_PATH } from '../../../static/svg';
import MainButton from '../../../components/buttons/MainButton';
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserInfo, setNeedUpdateTotalTicket } from '../../../redux/user/action';
import { serverApi } from '../../../services/serverApi';
import { detailPlayer } from 'common/src/api/player'

const EventBox = (props) => {
  const { playerEvent } = useSelector((state) => state.event);
  const { totalAcquisitionTicket, needUpdateTotalTicket } = useSelector((state) => state.user)
  const dispatch = useDispatch();

  useEffect(() => {
    if (needUpdateTotalTicket) {
      getInfoUser(props.playerId)
    }
  },[])

  const getInfoUser = async (playerId) => {
    const result = await serverApi(detailPlayer(playerId))
   
    if (result.data) {
      dispatch(getUserInfo(result.data.data))
      dispatch(setNeedUpdateTotalTicket(false))
    }
  }

  return (
    <>
      <div className="event-box">
        <Link to="/select-bingo">
          <HomeSlides playerEvent={playerEvent} />
        </Link>
        <div className="bottom-slides">
          <div className="box-ticket">
            <div className="ticket-img">
              <img src={ITEM_TICKET_YOKOHAMA_IMG_PATH} alt="" />
            </div>
            <div className="ticket-text"><span>チケット所持数:</span></div>
            <div className="ticket-point">
              <span>{needUpdateTotalTicket ? <i className="fa fa-refresh fa-spin" /> : totalAcquisitionTicket}</span>
              {/* <span><i className="fa fa-refresh fa-spin" /></span> */}
            </div>
          </div>
        </div>
        <div className="get-event-button">
          <Link to="/exchange-ticket" >
            <MainButton name="チケットを交換する" link="" />
          </Link>
        </div>
      </div>
    </>
  )
}
export default EventBox;