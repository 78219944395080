import React from 'react'
import StyleContentHowToPlay from './contentHowToPlay.style'

const ContentHowToPlay = () => {
  return (
    <StyleContentHowToPlay>
      <div className="play-body">
        <div className="play-title">
          <img src="icon/rainbow_kirakira_L.svg" alt="rainbow_kirakira_L"/>
          <h2>遊び方</h2>
          <img src="icon/icon_title-right.svg" className="imgRight" alt="rainbow_kirakira_R"/>
        </div>
        <div className="play-content">
          <div className="step">
            <div className="step-title">
              <img src="icon/play_step1.svg" alt="play_step1"/>
              <div>
                <h3>街を散策しよう</h3>
                <p>このゲームは、現在地を利用して実際に街を歩いて</p>
                <p>BINGOを楽しむゲームです。</p>
              </div>
            </div>
            <div className="step-play">
              <img src="images/play1.png" alt="play1" style={{margin:'15px 0'}} />
            </div>
          </div>

          <div className="step">
            <div className="step-title">
              <img src="icon/play_step2.svg" alt="play_step2"/>
              <div>
                <h3>チェックインポイントを発見！</h3>
                <p>ゲーム内の地図を見て、近くにポイントを</p>
                <p>見つけたらチェックイン！</p>
              </div>
            </div>
            <div className="step-play">
              <img src="images/play2.png" alt="play2" style={{margin:'15px 0'}} />
            </div>
          </div>

          <div className="step">
            <div className="step-title">
              <img src="icon/play_step3.svg" alt="play_step3" />
              <div>
                <h3>ビンゴパネル獲得!!</h3>
                <p>チェックインした場所に応じてビンゴパネルを獲得！</p>
                <p>BINGO目指して次のポイントを目指しましょう!</p>
              </div>
            </div>
            <div className="step-play">
              <img src="images/play3.png" alt="play3" style={{marginTop:'15px'}} />
            </div>
          </div>
        </div>
      </div>
    </StyleContentHowToPlay>
  )
}

export default ContentHowToPlay
