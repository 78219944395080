import React from 'react'

const StepBot = (props) => {
  const nextPage = () => {
    window.localStorage.removeItem('backLogin');
    window.localStorage.setItem("showTabBarBottom", true);
    window.localStorage.setItem("showTutorial", true);
  }
  return (
    <div className="tutorial-bot">
      <div className="bot-text">
        <a href="/" className="next" onClick={() => nextPage()}>TABINGOをはじめる</a>
      </div>
    </div>
  )
}

export default StepBot
