import React from 'react';
import './styles.scss';
import IconLocation from '../../../../static/svg/IconLocation'
import { STAMP_BINGO_CLEAR_IMG_PATH, ITEM_TICKET_YOKOHAMA_IMG_PATH } from '../../../../static/svg';
import { mainColor } from '../../../../helper/helper';
import { formatDateBingoLog } from '../../../../helper/helper';
import moment from 'moment';

const BingoLogClearItem = (props) => {
  const clearLines = props.data.clearLines;
  const date = moment((props.data.date)).format(formatDateBingoLog)
  let rewards = []
    
  clearLines.map((item) => {
    rewards.push(item.rewards)
  })
  const totalReward = rewards.reduce((a, b) => a + b)

  return (
    <div className="bingo-log-clear-item" >
      <div className="item-image">
        <img src={STAMP_BINGO_CLEAR_IMG_PATH} alt={props.idx} />
      </div>

      <div className="item-text">
        <div className="title"><span style={{color:mainColor}}>{props.data.title}</span>をクリアしました</div>
        <div>
          <div className="ticket"><img className="image-ticket" src={ITEM_TICKET_YOKOHAMA_IMG_PATH} /><span className="ticket-text">×{totalReward}枚 獲得</span></div>
          <div className="date">{date}</div>
        </div>
      </div>
    </div>
  )
}

export default BingoLogClearItem;
