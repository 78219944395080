import React from 'react'
import styled from 'styled-components'

const StyleStepOne = styled.div`
  text-align: center;

  img.logo {
    width: 190px;
    margin: 40px 0;
  }

  .content-step1 {
    font-weight: 600;
    font-size:14px;
  }

  p {
    padding: 2.5px;
    color: #273956;
  }

  @media only screen and (max-width: 320px) {
    img.logo {
      width: 180px;
      margin: 25px 0;
    }
  }
  @media only screen and (min-width: 1024px) {
    img.logo {
      width: 120px;
      margin: 0;
    }
    .content-step1 {
      font-weight: 600;
      font-size:11px;
    }
    .footer{
      height:0
    }
  }
`

const StepOne = () => {
  return (
    <StyleStepOne>
      <img className="logo" src="icon/logo.svg" alt="tabingo_LOGO" />
      <div className="content-step1">
        <p>TABINGOの世界へようこそ！</p>
        <p>このゲームでは、現在地をもとに</p>
        <p>実際に歩いて進めていきます</p>
        <p>まずは現在地を</p>
        <p><span className="color-blue">「BINGOMAP」</span>からチェック</p>
        <p>してみましょう</p>
      </div>
    </StyleStepOne>
  )
  
}

export default StepOne
