/**
 * @param {number} size
 * @param {any} fieldValue
 * @returns {{url: string}}
 */
export function getListEvents() {
  let url = `/events`;
  return { url }
}
export function getPlayerEvent(playerId) {
  let url = `/players/${playerId}/activities/events/yokohama`;
  return { url }
}