import React, { useState, useEffect } from 'react';
import './styles.scss';
import GobackHeader from '../../components/headers/GoBackHeader';
import CopyRight from '../../components/copyRights/CopyRight';
import { HEADER_COPYRIGHT_HEIGHT } from '../../helper/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationDetail, clearNotificationDetail, getNotifications } from '../../redux/notification/action';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { formatDate } from '../../helper/helper';
import Loading from '../../components/loading/Loading';
import ErrorScreen from '../errors/ErrorScreen';

const NotificationDetailScreen = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notificationId = useParams();
  const { notificationDetail, isGettingDetail, isNotFound } = useSelector((state) => state.notification);
  const date = moment(notificationDetail.registeredAt).format(formatDate);

  const [windowHeight, setWindowHeight] = useState(0);
  let resizeWindow = () => {
    setWindowHeight(window.innerHeight - HEADER_COPYRIGHT_HEIGHT);
  };

  useEffect(() => {
    dispatch(getNotificationDetail(notificationId));
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
  }, []);
  
  return (
    <>
    {
      (isGettingDetail && !isNotFound) ?
      <Loading /> :
      (isNotFound ? <ErrorScreen /> :
      <>
        <GobackHeader name="お知らせ詳細" />
        <div className="notification-detail-screen" >
          <div style={{ minHeight: windowHeight + "px" }}>
            {notificationDetail.thumbnail && <div className="notification-detail-image">
              <img src={notificationDetail.thumbnail} alt={notificationDetail.title} />
            </div>}
            <div className="notification-content">
              <div className="notification-title">
                {notificationDetail.title}
              </div>
              <div className="notification-date">{date}</div>
              <div className="notification-description">
                {notificationDetail.content}
              </div>
            </div>
          </div>
          <CopyRight />
        </div>
      </>
      )
    }
    </>
  )
}
export default NotificationDetailScreen;