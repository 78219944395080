import React from 'react';
import './styles.scss';
import IconLocation from '../../../../static/svg/IconLocation'
import { STAMP_BINGO_CLEAR_IMG_PATH, ITEM_TICKET_YOKOHAMA_IMG_PATH } from '../../../../static/svg';
import HexagonLineItem from './HexagonLineItem';
import { mainColor, goldColor } from '../../../../helper/helper';
import { formatDateBingoLog } from '../../../../helper/helper';
import moment from 'moment';

const BingoLogLineItem = (props) => {
  const clearLines = props.data.clearLines;
  let panels = props.data.panels
  const date = moment((props.data.date)).format(formatDateBingoLog)
  let rewards = []  
  clearLines.map((item) => {
    rewards.push(item.rewards)
  })
  const totalReward = rewards.reduce((a, b) => a + b)

  clearLines.map((item) => {
    item.panelIds.map((itemId)=>{
      panels[itemId -1].design = 'yellow' 
    })
  })

  return (
    <div className="bingo-log-line-item" >
      <div className="box-hexagon-icon">
        <div className="row">

        {panels && panels.slice(0, 3).map((panel, key) => (
            <HexagonLineItem data={panel.design} key={key} />
          ))}
        </div>
        <div className="row">
        {panels && panels.slice(3, 7).map((panel, key) => (
            <HexagonLineItem data={panel.design} key={key}/>
          ))}
        </div>
        <div className="row">
        {panels && panels.slice(7, 12).map((panel, key) => (
            <HexagonLineItem data={panel.design} key={key}/>
          ))}
        </div>
        <div className="row">
        {panels && panels.slice(12, 16).map((panel, key) => (
            <HexagonLineItem data={panel.design} key={key}/>
          ))}
        </div>
        <div className="row">
        {panels && panels.slice(16, 19).map((panel, key) => (
            <HexagonLineItem data={panel.design} key={key}/>
          ))}
        </div>
      </div>

      <div className="item-text">
        <div className="title">
          <p>{props.data.title}で</p>
          <p><span style={{color:goldColor}}>ビンゴを{clearLines.length}ライン</span>達成しました</p>
          </div>
        <div>
          <div className="ticket"><img className="image-ticket" src={ITEM_TICKET_YOKOHAMA_IMG_PATH} /><span className="ticket-text">×{totalReward}枚 獲得</span></div>
          <div className="date">{date}</div>
        </div>
      </div>
    </div>
  )
}

export default BingoLogLineItem;
