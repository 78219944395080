import styled from 'styled-components'

export const StyleTutorial = styled.div`
  height: 100%;
  background-color:#FFF;
  .color-blue {
    color: #10cad6;
  }

  .step-content {
    margin-top: 10px;
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width:700px;
    //height: 71px;

    .imgFooter {
      //margin-bottom: -5px;
      background-image: url("/icon/footer.svg");
      background-position: bottom;
      background-size: cover;
      min-height: 50px;
      background-repeat: no-repeat;
      // height: 48px;
    }

    .copyRight {
      background-color: #a0df71;
      color: #ffffff;
      text-align: center;
      font-size: 11px;
      // bottom: 0;
      //position: absolute;
      width: 100%;
      padding-bottom: 3px;
    }
  }

  // @media only screen and (min-width: 1026px) {
  //   .footer {
  //     //width: 700px;
      
  //     .imgFooter {
  //       min-height: 100px;
  //     }

  //     .copyRight {
  //       font-size: 18px;
  //       bottom: -20px;
  //     }
  //   }
  // }

  // //@media only screen and (min-width: 768px) {
  // //  .content {
  // //    width: 700px;
  // //    margin: 0 25%;
  // //  }
  // //}

  // @media only screen and (max-width: 1025px) and (min-width: 769px) {
  //   .imgFooter {
  //     height: 75px;
  //   }
  // }

  // @media only screen and (max-width: 321px) {
  //   .footer {
  //     img {
  //       width: 100%;
  //     }
  //   }

  //   .content-step1 {
  //     font-size: 11px;
  //   }
  // }
`
