import React, { useEffect, useState } from 'react';
import { BG_BINGO_SHEET_NORMAL_IMG_PATH, BG_BINGO_SHEET_COMPLETE_IMG_PATH } from '../../../static/png';
import ChallengeToSpots from '../../../static/svg/ChallengeToSpots';
import BingoChallengeTop from './bingoTop/BingoChallengeTop';
import BingoChallengeBottom from './bingoBottom/BingoChallengeBottom';
import JumpToTopButton from '../../../components/buttons/JumpToTopButton';
import CopyRightGreen from '../../../components/copyRights/CopyRightGreen';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSheetDetail, setLastChecked, updateSheetDetail } from '../../../redux/bingo/action';
import Loading from '../../../components/loading/Loading';
import GetTicketSuccessModal from '../../../components/modals/GetTicketSuccessModal';
import BingoClearModal from '../../../components/modals/BingoClearModal';
import './styles.scss';
import { DEFAULT_WIDTH, PANEL_COUNT, TABLET } from '../../../helper/helper';
import GoOtherBingo from './bingoTop/GoOtherBingo';
import { clearCheckin } from '../../../redux/spot/action';

const BingoChallengeScreen = (props) => {
  const dispatch = useDispatch();
  const playerId = window.localStorage.getItem('user_id');
  const playerBingoId = props.playerBingoId;
  const isClearBingo = props.sheetDetail?.isComplete;
  const [imgBackground, setImgBackground] = useState(BG_BINGO_SHEET_NORMAL_IMG_PATH)
  const [display, setDisplay] = useState(1);
  const { checkinData } = useSelector((state) => state.spot);
  const history = useHistory();

  useEffect(() => {
    document.body.style.overflow = "unset";
  }, [])

  useEffect(() => history.listen(() => {
    dispatch(setLastChecked(null))
  }), [dispatch, history])

  useEffect(() => {
    if (isClearBingo) {
      setImgBackground(BG_BINGO_SHEET_COMPLETE_IMG_PATH);
    }
  }, [isClearBingo])

  const jumpToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: 0,
    })
  }

  const renderBingoReward = () => {
    return (
      <a href="/exchange-ticket" className="div-img-reward">
        <div className="reward-text">
          <div className="reward-text-first">
            チケット
          </div>
          <div className="reward-text-second">
            交換
          </div>
        </div>
      </a>
    )
  }

  const clearCheckinData = () => {
    dispatch(clearCheckin());
    setDisplay(0)
  }

  const renderBingoInfo = () => {
    return (
      <Link to={`/bingo/${playerBingoId}/spots`}>
        <div className="div-bingo-info">
          <ChallengeToSpots width="50" height="50" />
        </div>
      </Link>
    )
  }

  return (
    <>
      <div
        className="bingo-challenge-screen"
        style={{
          backgroundImage: "url(" + imgBackground + ")"
        }}>
        {renderBingoReward()}
        {renderBingoInfo()}
        <BingoChallengeTop sheetDetail={props.sheetDetail} />
        {isClearBingo && <GoOtherBingo />}
        <BingoChallengeBottom sheetDetail={props.sheetDetail} />
        <JumpToTopButton onClick={() => jumpToTop()} />
        <CopyRightGreen />
      </div>
      {
        display === 1 &&
        checkinData?.checkinClearBingo &&
        <BingoClearModal name={props.sheetDetail?.name} onClose={() => clearCheckinData()} />
      }
      {
        display === 1 &&
        !checkinData?.checkinClearBingo &&
        checkinData?.numberCompleteLines > 0 &&
        <GetTicketSuccessModal number={checkinData?.numberCompleteLines} rewards={checkinData?.rewards} onClose={() => clearCheckinData()} />
      }
    </>
  )
}
export default BingoChallengeScreen;