import React, { useEffect } from 'react';
import { ITEM_TICKET_YOKOHAMA_IMG_PATH } from '../../../static/svg';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../components/loading/Loading';
import { serverApi } from '../../../services/serverApi';
import { detailPlayer } from 'common/src/api/player';
import { getUserInfo, setNeedUpdateTotalTicket } from '../../../redux/user/action';

const BingoToLP = () => {
  const playerId = window.localStorage.getItem('user_id')
  const { totalAcquisitionTicket, needUpdateTotalTicket } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const getInfoUser = async (playerId) => {
    const result = await serverApi(detailPlayer(playerId))
    if (result.data) {
      const player = result.data.data
      dispatch(getUserInfo(player))
    }
  }

  useEffect(() => {
    if (!playerId) {
        return;
    }
    if (totalAcquisitionTicket === "" || needUpdateTotalTicket) {
      dispatch(setNeedUpdateTotalTicket(false));
      getInfoUser(playerId);
    }
  }, [])

  return (
    <>
      {
        (totalAcquisitionTicket === "" || needUpdateTotalTicket) ?
        <Loading /> : 
        <div className="bingo-to-lp-swap">
          <div className="box-ticket">
            <div className="ticket-img">
              <img src={ITEM_TICKET_YOKOHAMA_IMG_PATH} alt="" />
            </div>
            <div className="ticket-text"><span>チケット所持数:</span></div>
            <div className="ticket-point">
              <span >{totalAcquisitionTicket}</span>
            </div>
            <div className="box-button">
              <a href="/exchange-ticket" className="button-text">
                <div className="button-text-inner">
                  チケット交換
                  <div className="icon-svg">
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 10.826L5.434 6.001L0 1.173L1.245 0L8 6.001L1.245 12" fill="#10CAD6"/>
                    </svg>
                  </div>
                </div>
              </a>
            </div>
        </div>
      </div>
      }
    </>
  )
}
export default BingoToLP;
