import React, { useState } from 'react';
import PopupModal from './PopupModal';
import { mainColor, greenColor } from '../../helper/helper';
import { ILLUST_PANEL_TICKET_YOKOHAMA_IMG_PATH } from '../../static/svg';
import IconGenreShrine from '../../static/svg/IconGenreShrine';

const GetTicketSuccessModal = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleClick = async () => {
    setIsLoading(true)
    await props.onClick();
  }
  return (
    <>
      <PopupModal onClose={props.onClose}>
      <div className="get-ticket-success-modal">
          <div className="panel-box">
            <img src={ILLUST_PANEL_TICKET_YOKOHAMA_IMG_PATH} />
          </div>
          <div className="title">
            <p>{props.number}ラインのビンゴを達成し</p>
            <p><span style={{ color: mainColor }}>横浜 旅チケット</span>{" "}<span>を</span></p>
            <p>{props.rewards}枚獲得しました</p>
          </div>
        </div>
      </PopupModal>
    </>
  )
}
export default GetTicketSuccessModal;