import React from 'react';
import { ITEM_TICKET_YOKOHAMA_2_IMG_PATH, TICKET_CLEAR_IMAGE_IMG_PATH, BINGO_LINE_IMG_PATH, STAMP_REWARD_GET_IMG_PATH } from '../../../../static/svg';
import { lightGoldColor, goldColor } from '../../../../helper/helper'
import './styles.scss';
const BingoTicketItem = (props) => {

  const isFirst = props.isFirst ? props.isFirst : false;
  const line = props.line;
  const isClear = line?.completeAt;
  const imgbingo = props.imgbingo;
  const isBingoClear = props.isBingoClear;

  return (
    <>
      {!isFirst && (
      <div className="bingo-ticket-item" style={{ backgroundColor: isClear ? lightGoldColor : null }}>
        <div className="div-image-ticket">
          <img className="img-ticket" src={isFirst ? imgbingo : (isClear ? STAMP_REWARD_GET_IMG_PATH : ITEM_TICKET_YOKOHAMA_2_IMG_PATH)} alt="" />
        </div>
        <div className="ticket-text"><span style={isClear ? { color: goldColor } : null}>{ `横浜 旅チケット×${ line?.rewards }枚` }</span></div>
        <div className="ticket-end-image">
          <img src={line?.iconUrl} />
        </div>
      </div>)}
    </>
  )
}
export default BingoTicketItem;