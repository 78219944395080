import { get_jwt } from './redux/user/action'
import { setUserInfo } from './redux/user/action'
import { serverApiNoAuth } from './services/serverApi'
import firebase from './services/firebase'
import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, BrowserRouter as Router, Redirect, useHistory } from 'react-router-dom'
import { updateTutorial } from './redux/user/action'
import Loading from './components/loading/Loading'
import TabBarBottom from './components/tabbarBottom/TabBarBottom'
import Error500 from './pages/errors/Error500'
import ErrorPage from './pages/errors/ErrorPage'
import ErrorScreen from './pages/errors/ErrorScreen'
import Login from './pages/login/Login'
import LoginMail from './pages/loginMail/LoginMail'
import MemberAgreement from './pages/info/MemberAgreement'
import PrivacyProtection from './pages/info/PrivacyProtection'
import PrivacyProtectionMobile from './pages/info/PrivacyProtectionMobile'
import TermOfUse from './pages/info/TermOfUse'
import routes from './routes'
import ForgotPassword from './pages/forgotPassword/ForgotPassword'
import SendMailSuccess from './pages/forgotPassword/SendMailSuccess'

import './sass/app.scss'
import './sass/style.scss'
import TermOfUseMobile from './pages/info/TermOfUseMobile'
import Tutorial from './pages/tutorialV2/tutorial'
import RegisterScreen from './pages/register/Register'
import RegisterMailSent from './pages/register/SendMailSuccess'
import {REGISTER} from './helper/helper'
import { setLogin } from './redux/user/action'
import { logout } from './services/authenticate'


const App = () => {
  const [user, loading, error] = useAuthState(firebase.auth());
  const dispatch = useDispatch();
  const showTutorial = window.localStorage.getItem("showTutorial");
  const isShowTutorial = useSelector((state) => state.user.isShowTutorial);
  const userInfo = useSelector((state) => state.user.userInfo);
  const login = useSelector((state) => state.user.login);

  if (window.localStorage.getItem("backLogin") === 'true' && window.location.pathname !== '/tutorial') {
    window.localStorage.removeItem("backLogin");
    logout();
    window.location.href = '/login';
  }

  useEffect(() => {
    if (window.localStorage.getItem("needAccessKey") === "true") {
      dispatch(setLogin(true));
    }
  }, [])
  useEffect(() => {
    if (showTutorial) {
      dispatch(updateTutorial())
    }
  }, [isShowTutorial])

  if (loading && !['/term-of-use-mobile', '/privacy-protection-mobile'].includes(window.location.pathname)) {
    return <Loading />;
  }

  if (error) {
    return <Error500 />;
  }
  else if (login && user) {
    if (window.localStorage.getItem("needAccessKey") === "true") {
      const sendAccessKey = async () => {
        const id = await user.getIdToken();
        const origin_name = window.localStorage.getItem("typeLogin");
        let nickName = '';
        if (origin_name == null) {
          nickName = '';
        } else {
          firebase.auth()
          .getRedirectResult()
          .then((result) => {
            window.localStorage.setItem('twitterId', result.additionalUserInfo.username);
            }).catch((error) => {
          });
          nickName = user.displayName;
        }
        const data = { nickName: nickName, access_token: id };
        if (!window.localStorage.getItem("user_id")) {
          const respond = await serverApiNoAuth.post("players/login", data);  
          window.localStorage.setItem("user_id", respond.data.data.id);
        }
        dispatch(get_jwt(id));
      };
      sendAccessKey();
    } else {
      const getId = async () => {
        const id = await user.getIdToken();
        dispatch(get_jwt(id));
      };
      getId();
      firebase.auth()
      .getRedirectResult()
        .then((result) => {
          window.localStorage.setItem('twitterId', result.additionalUserInfo.username);
        }).catch((error) => {
      });
    }
      return (
      <Router>
        <main id="main_body">
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}
            <Route path='/forgotpassword' render={() => { return <Redirect to='/' /> }}></Route>
            <Route path='/sendmailsuccess' render={() => { return <Redirect to='/' /> }}></Route>
            <Route path='/register-mail-sent' render={() => { return <Redirect to='/' /> }}></Route>
            <Route children={<ErrorScreen />} />
          </Switch>
        </main>
        {window.localStorage.getItem('showTabBarBottom') && <TabBarBottom />}
      </Router>
    );

  } else {
    switch (window.location.pathname) {
      case "/register-mail-sent":
        return <Router>
          <main id="main_body">
            <Route path="/register-mail-sent" exact={true} children={<RegisterMailSent />} />
            <Route path="/" exact={true} children={<RegisterScreen />} />
            </main>
        </Router>;
      case "/sendmailsuccess":
        return <Router>
          <main id="main_body">
            <Route path="/sendmailsuccess" exact={true} children={<SendMailSuccess />} />
            <Route path="/" exact={true} children={<Login />} />
            </main>
        </Router>;
      case "/register":
        return <Router>
        <main id="main_body">
          <Route path="/register" exact={true} children={<RegisterScreen />} />
          <Route path="/" exact={true} children={<Login />} />
          </main>
        </Router>;      
      case "/forgotpassword":
        return <Router>
          <main id="main_body">
            <Route path="/forgotpassword" exact={true} children={<ForgotPassword />} />
          </main>
        </Router>;
      case "/login":
        return <Router>
          <main id="main_body">
            <Route path="/login" exact={true} children={<LoginMail />} />
          </main>
        </Router>;
      case "/term-of-use":
        return <Router>
          <main id="main_body">
            <Route path="/term-of-use" exact={true} children={<TermOfUse />} />
            <Route path="/" exact={true} children={<Login />} />
          </main>
        </Router>;
      case "/tutorial":
        return <Router>
          <main id="main_body">
            <Route path="/tutorial" exact={true} children={<Tutorial />} />
            <Route path="/" exact={true} children={<Login />} />
          </main>
        </Router>;
      case "/privacy-protection":
        return <Router>
          <main id="main_body">
            <Route path="/privacy-protection" exact={true} children={<PrivacyProtection />} />
            <Route path="/" exact={true} children={<Login />} />
          </main>
        </Router>;
      case "/member-agreement":
        return <Router>
          <main id="main_body">
            <Route path="/member-agreement" exact={true} children={<MemberAgreement />} />
            <Route path="/" exact={true} children={<Login />} />
          </main>
        </Router>;
      case "/term-of-use-mobile":
        return <Router>
          <main id="main_body">
            <Route path="/term-of-use-mobile" exact={true} children={<TermOfUseMobile />} />
          </main>
        </Router>;
      case "/privacy-protection-mobile":
        return <Router>
          <main id="main_body">
            <Route path="/privacy-protection-mobile" exact={true} children={<PrivacyProtectionMobile />} />
            <Route path="/" exact={true} children={<Login />} />
          </main>
        </Router>;
      default:
        return (
          <Router>
            <main id="main_body">
              <Switch>
                <Route
                  path="/"
                  exact={true}
                  children={<Login />}
                />
                <Redirect to={'/'} />
              </Switch>
            </main>
          </Router>
        );
    }
  }
};

export default App;
