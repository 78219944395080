export const GET_LIST_HISTORY            = 'GET_LIST_HISTORY'
export const GET_LIST_HISTORY_SUCCESS    = 'GET_LIST_HISTORY_SUCCESS'
export const GET_LIST_HISTORY_FAIL       = 'GET_LIST_HISTORY_FAIL'

export const UPDATE_LIST_HISTORY         = 'UPDATE_LIST_HISTORY'

export const GET_DETAIL_HISTORY          = 'GET_DETAIL_HISTORY'
export const GET_DETAIL_HISTORY_FAIL     = 'GET_DETAIL_HISTORY_FAIL'
export const GET_DETAIL_HISTORY_SUCCESS  = 'GET_DETAIL_HISTORY_SUCCESS'
export const NEED_RELOAD_HISTORY         = 'NEED_RELOAD_HISTORY'
export const RELOAD_HISTORY_SUCCESS      = 'RELOAD_HISTORY_SUCCESS'
