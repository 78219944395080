import React, {useEffect} from 'react'
import styled from 'styled-components'

const StyleJumpToTop = styled.div`
  .icon-btnJumToTop{
    width: 36px;
    border-radius:50%;
    position: fixed;
    //right: 10px;
    bottom: 85px;
    display: none;
    z-index: 999;
    box-shadow: 0px 3px 6px #0000001A;
    opacity: 1;
  }
`

const JumpTopButton = (props) => {
  let right = 10;
  if (window.innerWidth > 1026) {
    right = Math.ceil((window.innerWidth - 700) / 2) + right
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let scroll     = window.scrollY
      let jumpButton = document.querySelector('#btn-jump')

      if (jumpButton) {
        if (scroll >= 20) {
          jumpButton.style.display = 'block'
        } else {
          jumpButton.style.display = 'none'
        }
      }
    })
  }, [])

  return (
    <StyleJumpToTop className="jump_top_button">
      <img
        src="icon/jump_to_top.svg"
        id="btn-jump"
        className="icon-btnJumToTop"
        alt="jump to top"
        onClick={props.onClick}
        style={{right: `${right}px`}}
      />
    </StyleJumpToTop>
  )
}

export default JumpTopButton
