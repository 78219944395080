export const mainColor = '#10CAD6';
export const glayColor = '#96A4BC';
export const lightGoldColor = '#FFFCE1';
export const blackColor = '#000000';
export const greenColor = '#71C400';
export const whiteColor = '#FFFFFF';
export const backgroundGlay = '#F2F5F9';
export const goldColor = '#EFBA04';
export const starColor = '#FFE600';

export const HEADER_TABBAR_COPYRIGHT_HEIGHT = 157;
export const HEADER_TABBAR_HEIGHT = 104;
export const TABBAR_HEIGHT = 56;
export const HEADER_HEIGHT = 48;

export const HEADER_COPYRIGHT_HEIGHT = 165;
export const HEADER_COPYRIGHT_HOME = 493;
export const HEADER_TABBAR_COPYRIGHT_HOME = 493;

export const formatDate = 'YYYY/MM/DD';
export const formatDateJA = 'YYYY年MM月DD日';
export const formatDateEventJA = 'YYYY年M月D日';
export const formatDateNoYearJA = 'MM月DD日';
export const formatDateNoYearEventJA = 'M月D日';
export const formatDateBingoLog = 'YYYY/MM/D';

export const rgbOrangeColor = 'rgb(255, 150, 3)';
export const rgbBlueColor = 'rgb(51, 142, 224)';
export const rgbGreenColor = 'rgb(113, 196, 0)';
export const rgbRedColor = 'rgb(255, 78, 79)';

export const rgbGreen = 'rgb(113,196,0)';
export const rgbRed = 'rgb(255,78,79)';
export const rgbOrange = 'rgb(255,150,3)';
export const rgbBlue = 'rgb(51,142,224)';

export const greenText = '緑';
export const redText = '赤';
export const blueText = '青';
export const orangeText = '黄';

export const VALIDATE_ERROR = 'validate-error';
export const MIN_PASSWORD = 8;
export const MAX_PASSWORD = 14;
export const MAX_NAME = 10;
export const REGEX_NUMBER = /^\d+$/;
export const REGEX_PASSWORD = /(?=.*[A-Z])(?=.*\d)(?!.*[^a-zA-Z0-9]).{8,}/;
export const REGEX_MAIL_REGISTER = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const LENGTH_ZIP = 7;
export const REGISTER_MAIL_CONFIRM = "登録のためメールアドレスに確認メールを送りました。そちらより登録の完了をお願いいします。";
export const REGISTER = "register";
export const ERROR_MAIL_REGISTED = "auth/email-already-in-use";

export const STATUS_CODE_409 = 409;
export const STATUS_CODE_201 = 201;
export const SENDMAILFORGOT = "パスワード再設定のメールを送信しました \n メールのご確認をお願いいたします";
export const DEFAULT_WIDTH = 375;
export const PANEL_COUNT = 19;
export const TABLET = 768;
