import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import StepTitle from './stepTitle'

export const StyleStepTwo = styled.div`
  text-align: center;
  flex-direction:column;
  display:flex;
  height:100%;
`

const StepTwo = (props) => {
  const widthImage = (window.innerWidth - 25)/2;
  const heightImage = widthImage*580/350;
  const divHeight = useRef(null);
  const [displayHeight, setDisplayHeight] = useState("")
  useEffect(()=>{ 
    if(heightImage > divHeight.current.clientHeight){
      setDisplayHeight(divHeight.current.clientHeight)
      
    }
    else{
      setDisplayHeight(heightImage)
    }
  })

  const listContent = [
    'BINGOMAPは、',
    '6角形の区画で色別に分かれています。'
  ]

  return (
    <StyleStepTwo>
      <StepTitle title="BINGOMAPって??" listContent={listContent}/>
      <div ref={divHeight} style={{ marginBottom:'22px', flex:1,  maxHeight:displayHeight+"px" }}>
        <img className="image-tutorial" src="images/tutorialimg_1.png" alt="tutorialimg_1" style={{ width: displayHeight*350/580+"px",height: displayHeight+"px" }} />
      </div>
    </StyleStepTwo>
  )
}

export default StepTwo
